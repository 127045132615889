import { Box } from '@mui/material';
import ImportTab from 'pages/adminCreativeApproval/components/ImportTab';
import { withAdmin } from '../../state/admin';

const AdminCreativePayments = () => {
  return (
    <Box sx={{ p: 4 }}>
      <ImportTab />
    </Box>
  );
};

export default withAdmin(AdminCreativePayments);

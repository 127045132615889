import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { WhitelistEntry } from 'API';
import { FC } from 'react';

interface IRemoveModalProps {
  isModalOpen: boolean;
  entry: WhitelistEntry;
  onClose: () => void;
  onAccept: () => void;
}

const RemoveModal: FC<IRemoveModalProps> = ({
  entry,
  onClose,
  onAccept,
  isModalOpen,
}) => {
  return (
    <Dialog onClose={onClose} open={isModalOpen} fullWidth={true}>
      <DialogTitle sx={{ fontWeight: 500 }}>Remove Email</DialogTitle>
      <DialogContent>
        <div className="text-center border-2 border-dotted rounded-[8px]	border-main-purple px-10 py-2 bg-secondary-purple">
          <Typography className="text-main-purple">
            Are you sure you want to remove {entry.email} ?
          </Typography>
        </div>
      </DialogContent>

      <DialogActions sx={{ gap: 2 }}>
        <Button
          onClick={onClose}
          className="text-main-light-blue font-semibold"
        >
          Cancel
        </Button>
        <Button
          onClick={onAccept}
          className="text-main-light-blue font-semibold"
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveModal;

export const getCreativeRequests = /* GraphQL */ `
  query ListCreativeRequests(
    $filter: ModelCreativeRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreativeRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandBriefId
        status
        tiktokCreativeUrl
        creativePreviewUrl
        tiktokVideoCode
        brandComment
        adminComment
        creatorComment
        adminApproval
        creatorVisibility
        createdAt
        updatedAt
        uniqueId
        approvedAds {
          items {
            id
            creativeRequestId
            ad_id
            approvedAdType
            adName
          }
        }
        brief {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          active
          brandId
          brandProfile {
            userProfile {
              avatar
            }
          }
        }
        creatorProfile {
          id
          uniqueId
          email
          name
          hashtags
          description
          userType
          tiktokHandler
          youtubeHandler
          instagramHandler
          phoneNumber
          country
          avatar
          vertical
          termsAndConditions
          createdAt
          updatedAt
        }
      }
      nextToken
      __typename
    }
  }
`;

export const getCreativeRequestsByBrandBriefId = /* GraphQL */ `
  query ListCreativeRequests(
    $brandBriefId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCreativeRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creativeRequestsByBrandBriefId(
      brandBriefId: $brandBriefId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandBriefId
        status
        tiktokCreativeUrl
        creativePreviewUrl
        tiktokVideoCode
        brandComment
        adminComment
        creatorComment
        adminApproval
        creatorVisibility
        createdAt
        updatedAt
        uniqueId
        approvedAds {
          items {
            id
            creativeRequestId
            ad_id
            approvedAdType
            adName
          }
        }
        brief {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          active
          brandId
          brandProfile {
            userProfile {
              avatar
            }
          }
        }
        creatorProfile {
          id
          uniqueId
          email
          name
          hashtags
          description
          userType
          tiktokHandler
          youtubeHandler
          instagramHandler
          phoneNumber
          country
          avatar
          vertical
          termsAndConditions
          createdAt
          updatedAt
        }
      }
      nextToken
      __typename
    }
  }
`;

export const getCreativeRequestsByDate = /* GraphQL */ `
  query CreativeRequestsByDate(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreativeRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creativeRequestsByDate(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandBriefId
        status
        tiktokCreativeUrl
        creativePreviewUrl
        tiktokVideoCode
        brandComment
        adminComment
        creatorComment
        adminApproval
        creatorVisibility
        createdAt
        updatedAt
        uniqueId
        approvedAds {
          items {
            id
            creativeRequestId
            ad_id
            approvedAdType
            adName
          }
        }
        brief {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          active
          brandId
          brandProfile {
            userProfile {
              avatar
            }
          }
        }
        creatorProfile {
          id
          uniqueId
          email
          name
          hashtags
          description
          userType
          tiktokHandler
          youtubeHandler
          instagramHandler
          phoneNumber
          country
          avatar
          vertical
          termsAndConditions
          createdAt
          updatedAt
        }
      }
      nextToken
      __typename
    }
  }
`;

export const getAllCreatorIds = /* GraphQL */ `
  query UserProfilesByUserType(
    $userType: USER_TYPES!
    $sortDirection: ModelSortDirection
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userProfilesByUserType(
      userType: $userType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;

import { useLazyQuery } from '@apollo/client';
import {
  GetCreativeRequestQuery,
  GetCreativeRequestQueryVariables,
  SearchCreativeRequestsQuery,
  SearchCreativeRequestsQueryVariables,
} from 'API';
import { getCreativeRequest, searchCreativeRequests } from 'graphql/queries';
import { getQuery } from 'hooks/utils';

export const UseGetCreativeRequest = () => {
  const [getCreative, { data, loading, error }] = useLazyQuery<
    GetCreativeRequestQuery,
    GetCreativeRequestQueryVariables
  >(getQuery(getCreativeRequest));

  return {
    getCreativeRequest: getCreative,
    data: data?.getCreativeRequest,
    loading,
    error,
  };
};

export const UseSearchCreativeRequests = () => {
  const [searchRequests, { data, error, loading }] = useLazyQuery<
    SearchCreativeRequestsQuery,
    SearchCreativeRequestsQueryVariables
  >(getQuery(searchCreativeRequests));

  return {
    error,
    loading,
    searchRequests,
    page: data?.searchCreativeRequests?.page,
    pageSize: data?.searchCreativeRequests?.pageSize,
    requests: data?.searchCreativeRequests?.items || [],
    totalItems: data?.searchCreativeRequests?.totalItems,
  };
};

import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import {
    FieldValues,
    useController,
    UseControllerProps,
} from 'react-hook-form';

interface Props {
    label: string;
    initialValue?: any;
    size?: 'small' | 'medium';
    isDisabled?: boolean;
    options: { label: string; value: any }[];
}

export default <T extends FieldValues>({
    name,
    label,
    control,
    rules,
    options,
    isDisabled,
    initialValue = '',
    ...rest
}: Props & UseControllerProps<T>) => {
    const {
        field: { ref, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
        defaultValue: initialValue,
    });

    return (
        <>
            <FormControl fullWidth={true}>
                <InputLabel error={!!error?.message}>{label}</InputLabel>
                <Select
                    disabled={isDisabled}
                    fullWidth={true}
                    variant="outlined"
                    label={label}
                    margin="none"
                    error={!!error?.message}
                    inputRef={ref}
                    {...inputProps}
                    {...rest}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {!!error?.message && (
                <Box>
                    <FormHelperText error={true}>{error.message}</FormHelperText>
                </Box>
            )}
        </>
    );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export interface IImportModalProps {
  title?: string;
  message: string;
  onCancel: () => void;
}
const OopsModal = ({
  message,
  onCancel,
  title = 'Oops',
}: IImportModalProps) => (
  <Dialog open={true}>
    <DialogTitle
      sx={{
        fontFamily: 'Oswald',
        fontWeight: 600,
        fontSize: 22,
        textTransform: 'uppercase',
      }}
    >
      {title}
    </DialogTitle>

    <DialogContent
      sx={{
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        px: 4,
        textOverflow: 'ellipsis',
      }}
    >
      {message}
    </DialogContent>

    <DialogActions>
      <Button disableRipple={true} onClick={onCancel}>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default OopsModal;

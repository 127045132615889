import { BrandBrief, BRAND_BREIFS_WITH_BRAND_PROFILE } from 'API';
import Table, { Tdata } from 'components/table/Table';
import { UseGetBrandBriefById } from 'hooks';
import CampaignBriefDetails from 'pages/campaignBriefDetails/campaignBriefDetails';
import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICreatorBriefListProps, withCreatorBriefList } from 'state/dashboard';
import { withProfile } from 'state/profileSteps';
import { AuthRoutes, CreatorRoutes, ProfileProps } from 'utils';

export const CreatorBriefs: FC<ProfileProps & ICreatorBriefListProps> = ({
  profileState: { data },
  briefList,
  loading,
  changeBriefPage,
  briefPagination,
  currentPage,
}) => {
  const [selectedBrief, setSelectedBrief] =
    useState<BRAND_BREIFS_WITH_BRAND_PROFILE | null>(null);
  const [tableData, setTableData] = useState<Tdata[] | null>(null);
  const { getBrief } = UseGetBrandBriefById();
  const navigate = useNavigate();

  const checkParam = () => {
    const url = new URL(window.location.href);
    return !!url.searchParams.get('brief');
  };

  const getBrandBriefFromURL = useCallback(async () => {
    if (!window.location.href.includes('briefId')) {
      return;
    }

    const url = new URL(window.location.href);
    const briefId = url.searchParams.get('briefId');

    if (!briefId) {
      return;
    }

    const { data } = await getBrief({ variables: { id: briefId } });
    // FIXME: remove any assertion
    const { brandProfile, ...brief } = data?.getBrandBrief as any;
    setSelectedBrief({ brandInfo: brandProfile, ...brief });
  }, [getBrief]);

  useLayoutEffect(() => {
    getBrandBriefFromURL();
  }, []);

  useEffect(() => {
    if (!selectedBrief && checkParam()) {
      navigate(AuthRoutes.BrandBrief);
    }
  }, [navigate, selectedBrief]);

  useEffect(() => {
    const sorted = [...(briefList || [])].sort(
      (a, b) => Number(b?.active) - Number(a?.active)
    );

    const tableData = sorted.map((brief) => ({
      ...brief,
      id: brief?.id,
      img: brief?.brandInfo?.userProfile?.avatar || '/images/default-image.png',
      brandName: brief?.brandInfo?.name || 'N/A',
      activationName: brief?.BriefName || 'N/A',
      objective: brief?.objective,
      vertical: brief?.vertical,
      status: brief?.active ? 'Active' : 'In-active',
      created: new Date(brief?.createdAt || '').toLocaleString().split(',')[0],
    }));

    setTableData(tableData);
  }, [briefList]);

  const onRowClick = (brief) => {
    setSelectedBrief(brief);
    if (!brief.active) {
      return;
    }

    const profileUserListed = brief?.interestedUsers?.some(
      (u) => u.id === data?.id && u.hasBeenShown
    );

    const needsConfirmation =
      !brief?.interestedUsers?.length || !profileUserListed;
    navigate(
      `${CreatorRoutes.BriefDetails}?id=${brief.id}&needsConfirmation=${needsConfirmation}`
    );
  };

  return (
    <>
      {selectedBrief ? (
        <CampaignBriefDetails
          userType={data?.userType as string}
          data={selectedBrief as unknown as BrandBrief}
          onBack={() => setSelectedBrief(null)}
          hashtags={
            selectedBrief.brandInfo?.hashtags?.length
              ? selectedBrief.brandInfo.hashtags
              : selectedBrief.brandInfo?.userProfile?.hashtags || []
          }
          description={
            selectedBrief?.brandInfo?.personalDescription ||
            selectedBrief.brandInfo?.userProfile?.description ||
            ''
          }
        />
      ) : (
        <Table
          mainlyData={briefList as Tdata[]}
          data={tableData as Tdata[]}
          header={{
            title: 'Brand Activations',
            icon: '',
            search: true,
          }}
          rows={[
            '',
            'brandName',
            'activationName',
            'vertical',
            'objective',
            'created',
            'status',
            'details',
          ]}
          colHeight={400}
          onRowClick={onRowClick}
          pagination={briefPagination || 0}
          loading={loading || tableData === null}
          rowWidth="w-[110px]"
          dataCy="brandBrief"
          extended={true}
          borderColor="#FF872F"
          firstRowName="BRAND"
          tableCurrentPage={Number(currentPage || 1)}
          changePage={changeBriefPage}
        />
      )}
    </>
  );
};

export default withProfile(withCreatorBriefList(CreatorBriefs));

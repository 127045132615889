import { DeleteOutline, Edit } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import Label from 'components/ui/label';
import Modal from 'components/ui/modal';
import { format, parseISO } from 'date-fns';
import {
  UseAddCreativeEarning,
  UseDeleteCreativeEarning,
  UseGetCreativeEarningsByCreativeId,
  UseUpdateCreativeEarning,
} from 'hooks/query';
import {
  LONG_DATE_FORMAT,
  MONTH_INDICES,
  MONTH_OPTIONS,
  NORMAL_DATE_FORMAT,
} from 'hooks/utils/constants';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import { z } from 'zod';
import {
  CreateCreativeRequestEarningsInput,
  CreativeRequestEarnings,
  EARNING_TYPE,
  UpdateCreativeRequestEarningsInput,
} from '../../API';
import Input from '../../components/ui/input';
import Select from '../../components/ui/select';
import useZodForm from '../../hooks/useZodForm';
import { ceilToNearestDecimal, getISODate } from '../../utils/utils';

const object = z.object({
  amount: z.number().positive().lte(10000),
  fromDate: z.string(),
  toDate: z.string(),
  month: z.string(),
  creativeRequestEarningId: z.string().optional(),
  creatorId: z.string().optional(),
});

interface Props {
  onClose: () => void;
  userProfileId: string;
  getCreativeEarnings?: () => void;
  earning: CreativeRequestEarnings | null;
  updateCreativeRequestStatus?: (newStatus: string, comment?: string) => void;
}

const addEarningsModal: React.FC<Props> = ({
  onClose,
  userProfileId,
  earning,
}) => {
  const [creativeEarnings, setCreativeEarnings] = useState<
    CreativeRequestEarnings[]
  >([]);
  const {
    getEarningsByCreative,
    data: creativeEarningsItems,
    loading: earningLoading,
  } = UseGetCreativeEarningsByCreativeId();
  const [fromDate, setFromDate] = useState(new Date().toDateString());
  const [toDate, setToDate] = useState('');
  const {
    addEarning,
    loading: addLoading,
    data: earningData,
  } = UseAddCreativeEarning();
  const { updateEarning, loading: updateLoading } = UseUpdateCreativeEarning();
  const { deleteEarning, loading: deleteLoading } = UseDeleteCreativeEarning();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useZodForm({
    schema: object,
    defaultValues: {
      amount: 0,
      fromDate: new Date().toISOString(),
      toDate: new Date().toISOString(),
      month: MONTH_OPTIONS[0]?.value || '',
    },
    mode: 'all',
  });

  const getEarnings = async () => {
    await getEarningsByCreative({
      variables: { creativeRequestId: earning?.creativeRequestId || '' },
      errorPolicy: 'ignore',
    });
  };

  useEffect(() => {
    getEarnings();
  }, []);

  useEffect(() => {
    if (!creativeEarningsItems) {
      return;
    }
    const items = (
      creativeEarningsItems.getCreativeEarningsByCreative?.items || []
    ).filter((i) => i) as unknown as CreativeRequestEarnings[];

    setCreativeEarnings(items);
  }, [creativeEarningsItems]);

  useEffect(() => {
    onReset();
  }, [earningData]);

  const onReset = async () => {
    setFromDate('');
    setToDate('');
    reset();
  };

  const onSubmit = async (
    data:
      | UpdateCreativeRequestEarningsInput
      | CreateCreativeRequestEarningsInput
  ) => {
    if (data?.creativeRequestEarningId) {
      await updateEarning({
        variables: {
          input: {
            creatorId: data.creatorId,
            creativeRequestEarningId: data.creativeRequestEarningId,
            amount: (Number(data.amount) || 0) * 0.1,
          },
        },
      });

      toast.success('Updated');
      return;
    }

    const monthIndex = MONTH_INDICES[data.month || ''];
    const date = new Date();
    date.setMonth(monthIndex);
    date.setDate(1);
    const formattedMonth = format(date, 'yyyy-MM-dd');

    const item = {
      creativeRequestId: earning?.creativeRequestId || '',
      fromDate: getISODate(fromDate),
      toDate: getISODate(toDate),
      amount: Number((Number(data.amount) * 0.1).toFixed(2)),
      month: formattedMonth,
    } as CreativeRequestEarnings;

    setCreativeEarnings((prev) => [item, ...prev]);
    await addEarning({
      variables: { ...item, userProfileId },
    });
    toast.success('Created');
  };

  useEffect(() => {
    getEarnings();
  }, [addLoading, updateLoading, deleteLoading]);

  const formatDate = (date: string, dateFormat = NORMAL_DATE_FORMAT) => {
    try {
      const parsed = parseISO(date);
      return format(parsed, dateFormat);
    } catch (e) {
      return date;
    }
  };

  const onEdit = (earning: CreativeRequestEarnings) => {
    if (earning.earningType === EARNING_TYPE.AUTOMATIC) {
      return;
    }

    reset({ ...earning, amount: Number(earning.amount.toFixed(2)) });
    setFromDate(format(earning.fromDate, LONG_DATE_FORMAT));
    setToDate(format(earning.toDate, LONG_DATE_FORMAT));
  };

  const onDelete = async (earning: CreativeRequestEarnings) => {
    if (earning.earningType === EARNING_TYPE.AUTOMATIC) {
      return;
    }

    await deleteEarning({
      variables: {
        input: {
          creatorId: earning.creatorId,
          creativeRequestEarningId: earning.creativeRequestEarningId,
        },
        condition: { earningType: { ne: EARNING_TYPE.AUTOMATIC } },
      },
    });
    toast.success('Deleted');
  };

  return (
    <>
      <Modal
        modalWidth="min-w-[700px]"
        title={`CREATIVE ${earning?.creativeUniqueId}`}
        isOpen={true}
        handleClose={() => onClose()}
      >
        {/* @ts-ignore */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="brand-dashboard__profile-group mt-5 flex gap-x-2 gap-y-2 align-items-stretch">
            <Input
              type="number"
              errors={errors}
              name="amount"
              step="0.01"
              label="Media spend amount"
              placeholder="Add Earnings"
              register={register}
            />

            <div className="flex-col">
              <Label name="From Date"></Label>
              <DatePicker
                required={true}
                name="fromDate"
                className="profile-input"
                value={fromDate}
                onChange={(date) =>
                  date && setFromDate(format(date, LONG_DATE_FORMAT))
                }
              />
            </div>

            <div>
              <Label name="To Date"></Label>
              <DatePicker
                required={true}
                name="toDate"
                className="profile-input"
                value={toDate}
                onChange={(date) =>
                  date && setToDate(() => format(date, LONG_DATE_FORMAT))
                }
              />
            </div>

            <div className="h-auto max-h-[25px] min-w-[25%]">
              <Select
                className="min-w-[100%]"
                required={true}
                name="month"
                options={MONTH_OPTIONS}
                control={control}
                label="Month"
              />
            </div>
          </div>

          <div>
            <div className="flex flex-column row-gap-3 max-h-[100px] overflow-y-auto overflow-x-hidden min-h-[70px] p-2">
              {!earningLoading &&
              !addLoading &&
              !updateLoading &&
              !deleteLoading ? (
                creativeEarnings
                  .filter((e) => !!e.amount)
                  .map((e, i) => {
                    return (
                      <div className="border-b-2 p-1 border-slate-100" key={i}>
                        <div className="flex justify-between items-center">
                          <h3>
                            <b>${ceilToNearestDecimal(e.amount)}</b> From{' '}
                            {formatDate(e.fromDate)}
                            &#32;
                            {formatDate(e.toDate)} Added{' '}
                            {formatDate(e.updatedAt)}
                          </h3>

                          <div>
                            <IconButton onClick={() => onEdit(e)}>
                              <Edit sx={{ fontSize: 20 }} />
                            </IconButton>

                            <IconButton onClick={() => onDelete(e)}>
                              <DeleteOutline sx={{ fontSize: 20 }} />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="flex justify-center items-center">
                  <CircularProgress className="w-8 h-8 text-main-black" />
                </div>
              )}
            </div>
          </div>

          <div
            className="
          flex sm:flex-row w-full sm:justify-center
          font-sans text-base text-white font-bold flex-col-reverse gap-4 items-center px-6 mt-6"
          >
            <div>
              <button
                type="button"
                onClick={onClose}
                className="creator-button bg-[#F1EBDF] font-[500] text-[14px] text-black"
              >
                Cancel
              </button>
            </div>

            <button type="submit" className="creator-button bg-black">
              Update
            </button>
          </div>
        </form>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default addEarningsModal;

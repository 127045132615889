import { Button, Typography } from '@mui/material';
import { FC } from 'react';

interface ExportCardProps {
    onExport: () => Promise<void>;
    title?: string;
    value?: string | number;
    isButtonDisabled?: boolean;
}

const ExportCard: FC<ExportCardProps> = ({
    title,
    value,
    onExport,
    isButtonDisabled,
}) => {
    return (
        <div className="min-w-[330px] min-h-[120] bg-[#F5F1E8] rounded-3xl px-[30px] py-[26px]">
            <div className="flex flex-col gap-y-2">
                <Typography className="text-[14px] text-gray-600 font-[600] font-oswald uppercase">
                    {title}
                </Typography>

                <div className="flex justify-between items-center">
                    <span className="font-[600] text-[33px]">{value}</span>

                    <Button
                        disabled={isButtonDisabled}
                        disableRipple={true}
                        onClick={onExport}
                        className="bg-black text-white rounded-[16px] px-4 py-2 font-[500] text-[15px] disabled:bg-gray-600"
                    >
                        Export
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ExportCard;

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import styled from 'styled-components';

export const renderCustomDataGridTableHeaderCell = (
  headers: GridColDef[],
  className: string
): GridColDef[] =>
  headers.map((h) => ({
    ...h,
    renderHeader: ({ colDef }) => (
      <b className={className}>{colDef.headerName}</b>
    ),
  }));

export const StyledDataGrid = styled(DataGrid)`
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
  min-height: 600px !important;
  border-radius: 16px !important;
  overflow-x: hidden !important;
  outline: none !important;
  border: none !important;

  & .MuiDataGrid-cell {
    border-color: #f5f5f4 !important;
    outline: none !important;
  }

  & .MuiDataGrid-overlay {
    background: #fefefe;
  }

  & .MuiDataGrid-columnHeader {
    color: #212121 !important;
    text-transform: uppercase !important;
    outline: none !important;
    font-family: 'OswaldBold', sans-serif !important;
    font-optical-sizing: auto !important;

    & .MuiDataGrid-columnHeaderTitleContainerContent {
      font-family: 'OswaldBold', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      font-optical-sizing: auto !important;
    }

    & .MuiDataGrid-columnHeaderTitle {
      font-family: 'OswaldBold', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      font-optical-sizing: auto !important;
    }

    & .MuiDataGrid-columnSeparator {
      visibility: visible;
    }
  }

  & .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }

  & .MuiDataGrid-iconButtonContainer {
    visibility: visible !important;
  }

  & .MuiDataGrid-sortIcon {
    opacity: inherit !important;
  }

  & .MuiDataGrid-row {
    cursor: pointer !important;
  }
`;

import { BrandProfile } from 'API';
import { GetBrandList } from 'hooks';
import { FC, Fragment, useEffect, useState } from 'react';
import { withProfile } from 'state/profileSteps';
import { ProfileProps } from 'utils';
import BrandBody from './brandBody';
import BrandForm from './brandForm';
import Meter from './meter';
import { NoDataFound } from './noData';

export const HomePage: FC<ProfileProps> = ({ profileState: { data } }) => {
  const { getBrandList, data: brandList } = GetBrandList();
  const [brand, setBrand] = useState<BrandProfile>();

  useEffect(() => {
    if (!brandList) {
      getBrandList();
      return;
    }
    setBrand(brandList?.find((bp) => bp.userProfileBrandId === data?.id));
  }, [brandList]);

  if (!data) return <Fragment key="no profie found" />;
  return (
    <div className="brand-dashboard__items brand-profile-items p-0">
      <div className="brand-dashboard__item title text-[#0E0D0D] uppercase head-text text-[16px] font-[700]">
        Brand identity
      </div>
      <div className="brand-dashboard__item border border-[#F5F1E8]">
        <Meter />
        {!brand && <NoDataFound />}
        {brand && <BrandBody data={brand} />}
      </div>
      <div className="brand-dashboard__item border border-[#F5F1E8]">
        <BrandForm />
      </div>
    </div>
  );
};

export default withProfile(HomePage);

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { BrandBrief } from 'API';
import { BlueCloseIcon, RedHeartIcon } from 'assets/icons/icons';
import { FC } from 'react';

interface IInterestedModalProps {
    brandBrief?: BrandBrief;
    isOpen: boolean;
    onCancel?: () => void;
    onSubmit?: () => Promise<void>;
}

const InterestedModal: FC<IInterestedModalProps> = ({ brandBrief, isOpen, onCancel, onSubmit }) => (
    <Dialog open={isOpen} fullWidth={true}>
        <DialogTitle>
            <div className="flex flex-col gap-y-1">
                <Typography className="font-[600] text-[24px] font-oswald uppercase">
                    Thanks for checking out the
                </Typography>
                <Typography className="font-[400] text-[14px] font-inter uppercase">
                    {brandBrief?.BriefName} activation
                </Typography>
            </div>
        </DialogTitle>

        <DialogContent sx={{ py: 4 }}>
            <Typography className='text-[14px] font-[400] font-inter'>Are you interested in this activation?</Typography>
        </DialogContent>

        <DialogActions sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button onClick={onSubmit} endIcon={<RedHeartIcon />}>Yes</Button>
            <Button onClick={onCancel} endIcon={<BlueCloseIcon />}>No</Button>
        </DialogActions>
    </Dialog>
);

export default InterestedModal;

import { GridColDef } from '@mui/x-data-grid';
import CreativeVideoThumbnail from './CreativeVideoThumbnail';
import { getStatusLabel, requestsSortComparator } from './utils';

export enum BrandLoginViewTabs {
  EverydayContent = 'Everyday content',
  ComingSoon = 'Coming Soon',
}

export const TABLE_COLUMNS: GridColDef[] = [
  {
    field: 'preview',
    headerName: 'Preview',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    cellClassName: 'px-10',
    minWidth: 150,
    renderCell: ({ row }) =>
      row.tiktokCreativeUrl && (
        <CreativeVideoThumbnail videoPath={row.tiktokCreativeUrl} />
      ),
  },
  {
    field: 'briefName',
    headerName: 'Activation',
    flex: 1,
    renderCell: ({ row }) => row.brief?.BriefName || 'N/A',
  },
  {
    field: 'uniqueId',
    headerName: 'Creative Id',
    filterable: false,
    align: 'center',
    headerAlign: 'center',
    flex: 1,
  },
  {
    field: 'status',
    headerAlign: 'center',
    headerName: 'Status',
    filterable: false,
    sortable: true,
    align: 'center',
    flex: 1,
    sortComparator: requestsSortComparator,
    renderCell: ({ value }) => getStatusLabel({ status: value }),
  },
  {
    field: 'details',
    headerAlign: 'center',
    headerName: 'Details',
    align: 'center',
    filterable: false,
    sortable: false,
    flex: 1,
  },
];

export const MOCK_TABLE_DATA = [{ id: 'fake id' }];

import { useNavigate } from 'react-router-dom';
import { UnAuthRoutes } from 'utils';
import { Center, Column, Row } from '../common';

export const Footer = () => {
  const navigate = useNavigate();
  const redirect = (url: string) => {
    window.location.href = `${process.env.REACT_APP_URL}${url}`;
  };
  return (
    <>
      <Center
        className="relative justify-start -mt-[40px] bg-[#202020] rounded-b-[40px] h-[370px] w-full xs:pl-[40px] pl-[10px] z-[5]
      [&>h1]:text-[30px] [&>h1]:text-[white] xs:[&>h1]:text-[40px]"
      >
        <h1>hello@edcsquared.io</h1>
      </Center>
      <Column className="w-full bg-[#101010]">
        <Row className="relative -mt-[40px] w-full bg-[#101010] items-start pt-[6%] justify-start max-h-[500px] z-[3] font-satoshi text-white flex-col pl-[20px] md:pl-[0px] md:flex-row ">
          <Center className="h-[90%] flex-col justify-between w-[30%] my-[0] mx-[7%]">
            <img className="h-[100px]" src={'/images/logo-new-white.svg'} />
          </Center>
          <Row className="h-[90%] justify-between items-start w-full flex-col md:flex-row md:w-[50%]">
            <Column className="justify-start items-start max-w-[240px] w-[50%] md:w-[30%] flex-row md:flex-col">
              <h1 className="text-[21px] pb-[24px] h-[42px]">EDC squared</h1>
              <h3
                className="cursor-pointer h-[32px] mb-[10px]"
                onClick={() => redirect('creators')}
              >
                For Creators
              </h3>
              <h3
                className="cursor-pointer h-[32px] mb-[10px]"
                onClick={() => redirect('brands')}
              >
                For Brands
              </h3>
              <h3
                className="cursor-pointer h-[32px] mb-[10px]"
                onClick={() => redirect('purpose')}
              >
                Our Purpose
              </h3>
            </Column>
            <Column className="justify-start items-start max-w-[240px] w-[50%] md:w-[30%] flex-row md:flex-col">
              <h1 className="text-[21px] pb-[24px] h-[42px]">Support</h1>
              <h3
                className="cursor-pointer h-[32px] mb-[10px]"
                onClick={() => redirect('connect')}
              >
                Let&#39;s Connect
              </h3>
              <h3
                className="cursor-pointer h-[32px] mb-[10px]"
                onClick={() => redirect('privacy-policy')}
              >
                Privacy policy
              </h3>
            </Column>
            <Column className="justify-start items-start max-w-[240px] w-[50%] md:w-[30%] flex-row md:flex-col">
              <h1 className="text-[21px] pb-[24px] h-[42px]">Join Us</h1>
              {/* TODO: Use history push like this in all the places */}
              <h3
                className="cursor-pointer h-[32px] mb-[10px]"
                onClick={() => navigate(UnAuthRoutes.Login)}
              >
                Login or Sign up
              </h3>
            </Column>
          </Row>
        </Row>
        <h4 className="text-white w-full text-left pl-[20px] pb-[10px] mt-[20px] md:mt-0">
          © 2024 Copyright EDC Squared. All Rights Reserved.
        </h4>
      </Column>
    </>
  );
};

import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { FC } from "react";

export interface ISuccessModalProps {
    title?: string;
    message?: string;
    isOpen: boolean;
    onClose: () => void;
}

const SuccessModal: FC<ISuccessModalProps> = ({ isOpen, onClose, title, message }) => (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true}>
        <DialogTitle className="font-oswald font-[700] text-[20px] uppercase">
            <Box className="flex justify-between items-center">
                <span>{title}</span>

                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>
        </DialogTitle>

        <DialogContent>
            <Typography className="font-roboto font-[500] text-[16px]">{message}</Typography>
        </DialogContent>
    </Dialog>
)

export default SuccessModal
import cn from 'classnames';
import { FC, ReactNode } from 'react';

interface ICreatorTileProps {
  title?: string;
  subtitle?: string;
  titleClassNames?: string;
  subtitleClassNames?: string;
  children?: ReactNode;
}

const CreatorTile: FC<ICreatorTileProps> = ({
  title,
  subtitle,
  titleClassNames,
  subtitleClassNames,
  children,
}) => {
  const hasChildren = children != null;

  return (
    <div className="grow flex flex-col rounded-3xl bg-[#F5F1E8] px-[22px] py-[25px] xl:w-[calc(25%-120px)] sm:w-[calc(50%-20px)] w-[calc(100%-20px)] h-[120px]">
      {!hasChildren ? (
        <>
          <div className="flex justify-between items-center">
            <span
              className={cn(
                titleClassNames,
                'uppercase text-[14px] font-[700] font-oswald text-gray-500'
              )}
            >
              {title}
            </span>
          </div>

          <div>
            <p
              className={cn(
                subtitleClassNames,
                'text-black font-inter font-[600] text-[28px] text-truncate'
              )}
            >
              {subtitle}
            </p>
          </div>
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default CreatorTile;

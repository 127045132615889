import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { CircularProgress } from '@mui/material';
import { useVideo } from 'hooks/useVideo';
import { getDriveGoogleUrl } from 'hooks/utils';
import { FC, useRef, useState } from 'react';
import {
  FacebookEmbed,
  TikTokEmbed,
  YouTubeEmbed,
} from 'react-social-media-embed';

interface IVideoProps {
  url: string;
  videoUrl: string;
  isMuted?: boolean;
}

const Video: FC<IVideoProps> = ({ url, videoUrl, isMuted }): JSX.Element => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { hasStartedPlaying, togglePause, isPaused, currentTime } =
    useVideo(videoRef);

  const hasControl = currentTime !== 0 && (loading ? false : isPaused);

  return url.includes('facebook') ? (
    <FacebookEmbed
      ref={videoRef}
      onClick={togglePause}
      url={videoUrl}
      width="100%"
      height={620}
    />
  ) : url.includes('youtube') ? (
    <YouTubeEmbed url={videoUrl} width="100%" height={620} />
  ) : url.includes('tiktok') ? (
    <TikTokEmbed
      onClick={togglePause}
      ref={videoRef}
      url={videoUrl}
      width="100%"
      height={620}
    />
  ) : url.includes('drive.google.com') && getDriveGoogleUrl(url) ? (
    <video
      onClick={togglePause}
      ref={videoRef}
      width="100%"
      className="h-[620px]"
      controls
    >
      <source src={getDriveGoogleUrl(url)} type="video/mp4" />
    </video>
  ) : videoUrl ? (
    <div
      onClick={togglePause}
      className="h-[500px] relative w-[286px] flex items-center"
    >
      <img
        src="/images/iPhone-bg.png"
        className="h-full w-full absolute m-auto block"
        alt=""
      />
      {(loading || isPaused) && !hasStartedPlaying && (
        <div className="absolute m-auto bg-black bg-opacity-30 w-[94%] h-[99%] rounded-[55px] left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
          <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <div className="w-[80px] h-[80px] rounded-full bg-white text-black flex items-center justify-center">
              {loading ? (
                <CircularProgress
                  sx={{
                    color: 'black',
                  }}
                  size={25}
                />
              ) : (
                <PlayArrowRoundedIcon
                  sx={{ color: 'black', cursor: 'pointer' }}
                  fontSize="large"
                />
              )}
            </div>
          </div>
        </div>
      )}
      <video
        autoPlay
        ref={videoRef}
        controls={hasControl}
        onClick={(e) => e.preventDefault()}
        className="outline-none object-contain m-[0] rounded-[45px] p-[6px] h-[unset]"
        muted={isMuted}
        onCanPlay={() => {
          setLoading(false);
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  ) : (
    <></>
  );
};

export default Video;

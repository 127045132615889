import { CREATIVE_STATUS } from '../../API';

export const getStatusColor = (status: CREATIVE_STATUS) => {
  switch (status) {
    case CREATIVE_STATUS.Submitted:
      return 'bg-[#1D1C1C]';
    case CREATIVE_STATUS.Approved:
      return 'bg-[#00B1B5]';
    case CREATIVE_STATUS.Rejected:
      return 'bg-[#D32F2F]';
    default:
      return 'bg-main-black';
  }
};

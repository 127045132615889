import { Box, FormHelperText, StandardTextFieldProps } from '@mui/material';
import { StyledTextField } from 'components/TextField';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export const TextInput = <T extends FieldValues>({
  name,
  control,
  rules,
  initialValue = '',
  autoComplete = 'off',
  ...rest
}: { initialValue?: any } & StandardTextFieldProps & UseControllerProps<T>) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: initialValue,
  });

  return (
    <>
      <StyledTextField
        error={!!error?.message}
        inputRef={ref}
        fullWidth={true}
        margin="none"
        autoComplete={autoComplete}
        {...inputProps}
        {...rest}
      />

      {!!error?.message && (
        <Box className="text-danger">
          <FormHelperText error={true}>{error.message}</FormHelperText>
        </Box>
      )}
    </>
  );
};

import { Cancel } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { StyledTextField } from 'components/TextField';
import { FC, useMemo } from 'react';

export interface ITagsProps {
  tags?: string[] | null;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onDeleteTag: (tag: string) => void;
  required?: boolean;
}

export interface ITagLabelProps {
  value: string;
  onDelete: (tag: string) => void;
}

const TagLabel = ({ value, onDelete }) => (
  <div className="bg-main-teal text-white text-[13px] rounded-3xl p-2 flex justify-between items-center min-w-[64px] text-nowrap h-[30px]">
    <div className="w-7/10 overflow-hidden text-clip">
      <p>{value}</p>
    </div>

    <div className="flex-1">
      <IconButton onClick={onDelete}>
        <Cancel sx={{ fontSize: 16 }} />
      </IconButton>
    </div>
  </div>
);

const Tags: FC<ITagsProps> = ({
  onKeyDown,
  onDeleteTag,
  tags,
  required,
  ...props
}) => {
  const tagLabels = useMemo(
    () =>
      tags?.map((t) => (
        <TagLabel key={t} value={t} onDelete={() => onDeleteTag(t)} />
      )),
    [onDeleteTag, tags]
  );

  return (
    <div className="flex gap-2">
      <StyledTextField
        className="flex-2"
        label="Tags"
        required={required}
        onKeyDown={(event) => onKeyDown(event)}
        {...props}
      />

      <div className="flex flex-1 flex-wrap gap-2">{tagLabels}</div>
    </div>
  );
};

export default Tags;

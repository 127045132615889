import {
  FullPageLoader,
  isValidRoute,
  replaceSubPath,
  ShouldRender,
} from 'components';
import BrandLogin from 'components/BrandLogin/BrandLogin';
import BrandLoginView from 'components/BrandLoginView/BrandLoginView';
import ToastContainer from 'components/toast';
import { LoginPage, LogoutPage } from 'pages';
import PreviewWindow from 'pages/previewWindow/previewWindow';
import { TermsAndConditions } from 'pages/termsAndConditions/index';
import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { withAuth } from 'state/auth';
import withError from 'state/error/withErrorHoc';
import {
  AuthProps,
  AuthRoutes,
  BrandRoutes,
  ErrorProps,
  UnAuthRoutes,
} from 'utils';
import AuthRouter from './AuthRouter';
import { AuthRoutesArray, UnAuthRoutesArray } from './RoutesConstants';

const MainRouter: React.FC<AuthProps & ErrorProps> = ({
  authState: { isLoading, isLoggedIn, email },
  ...rest
}) => {
  const [isValidPath, setIsValidPath] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const redirectToInValidRoute = (): void => {
    if (pathname.split('/').length === 3) {
      navigate(replaceSubPath(UnAuthRoutes.SubLogin));
    } else navigate(UnAuthRoutes.Login);
  };

  useEffect(() => {
    if (pathname === BrandRoutes.LinkTiktokAccount) {
      const url = new URL(window.location.href);
      if (url.searchParams.get('code')) {
        localStorage.setItem(
          'authTikTokCode',
          url.searchParams.get('code') || ''
        );
        const urlWithoutParams = url.origin + url.pathname;
        window.location.href = urlWithoutParams;
      }
    }
    if (pathname === BrandRoutes.LinkFacebookAccount) {
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code') || null;
      if (code) {
        localStorage.setItem('authFacebookCode', code);
        url.searchParams.delete('code');
        window.location.href = url.href;
        return;
      }
    }
    if (pathname === BrandRoutes.linkYoutubeAccount) {
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code') || null;
      if (code) {
        localStorage.setItem('authYoutubeCode', code);
        const urlWithoutParams =
          window.location.origin + window.location.pathname;
        window.location.href = urlWithoutParams;
        return;
      }
    }
    if (pathname === AuthRoutes.EditProfile) {
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code') || null;
      if (code) {
        if (url.href.includes('tiktok')) {
          localStorage.setItem('authTikTokCode', code);
          const urlWithoutParams = url.origin + url.pathname;
          window.location.href = urlWithoutParams;
        } else if (url.href.includes('instagram')) {
          localStorage.setItem('authInstagramCode', code);
          const urlWithoutParams = url.origin + url.pathname;
          window.location.href = urlWithoutParams;
        } else {
          localStorage.setItem('authYoutubeCode', code);
          const urlWithoutParams =
            window.location.origin + window.location.pathname;
          window.location.href = urlWithoutParams;
        }

        return;
      }
    }
    if (typeof isLoggedIn === 'boolean' && !isLoading) {
      if (
        isLoggedIn &&
        !isValidRoute(AuthRoutesArray, pathname) &&
        !pathname.includes(UnAuthRoutes.TermsAndConditions) &&
        !pathname.includes(UnAuthRoutes.BrandLogin)
      ) {
        navigate(replaceSubPath(AuthRoutes.Redirector));
      } else if (!isLoggedIn && !isValidRoute(UnAuthRoutesArray, pathname)) {
        redirectToInValidRoute();
      }
      setIsValidPath(true);
    }
  }, [isLoading, isLoggedIn, email]);

  return (
    <Fragment>
      <ToastContainer {...rest} />
      <ShouldRender if={!isValidPath}>
        <FullPageLoader />
      </ShouldRender>

      <ShouldRender if={isValidPath}>
        <>
          <Routes>
            <Route path={AuthRoutes.Logout} Component={LogoutPage} />
            <Route path={UnAuthRoutes.Preview} Component={PreviewWindow} />
            <Route path={UnAuthRoutes.Login} Component={LoginPage} />
            <Route path={UnAuthRoutes.SignUp} Component={LoginPage} />
            <Route path={UnAuthRoutes.ForgotPass} Component={LoginPage} />
            <Route path={UnAuthRoutes.Purpose} Component={LoginPage} />
            {/* remove_me test Route */}
            <Route path={UnAuthRoutes.BrandLogin} Component={BrandLogin} />
            <Route
              path={BrandRoutes.BrandLoginView}
              Component={BrandLoginView}
            />
            {/* remove_me test Route */}
            <Route
              path={UnAuthRoutes.TermsAndConditions}
              Component={TermsAndConditions}
            />
            <Route path={'*'} Component={AuthRouter} />
          </Routes>
        </>
      </ShouldRender>
    </Fragment>
  );
};

export default withError(withAuth(MainRouter));

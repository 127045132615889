import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button } from '@mui/material';
import classNames from 'classnames';
import useBreakpoints from 'hooks/useBreakpoints';
import { OTP_TOOLTIP_DURATION } from 'hooks/utils';
import { FC } from 'react';
import { toast } from 'react-toastify';
import TootlTipContent from './components/TooltipContent';

interface IOtpButtonsProps {
  onResend: () => Promise<void>;
  onCancel: () => void;
  isDisabled: boolean;
}

const OtpButtons: FC<IOtpButtonsProps> = ({
  onResend,
  onCancel,
  isDisabled,
}) => {
  const breakpoints = useBreakpoints();

  const handleResendOTP = () => {
    toast.dismiss();
    toast(<TootlTipContent />, {
      position: breakpoints !== 'sm' ? 'top-right' : 'top-center',
      autoClose: OTP_TOOLTIP_DURATION,
      hideProgressBar: true,
      className: classNames('top-[150px]  bg-[#EDF7ED] h-[80px]', {
        'right-[30px]': breakpoints !== 'sm',
        '-translate-x-1/2 left-[50%] w-[70%]': breakpoints === 'sm',
      }),
      bodyClassName: 'h-full items-start',
      icon: (
        <CheckCircleOutlineIcon className="fill-[#2f7d31]" color="success" />
      ),
    });
    onResend();
  };
  return (
    <div className="self-end">
      <Button disabled={isDisabled} onClick={onCancel} disableRipple>
        CANCEL
      </Button>

      <Button disabled={isDisabled} onClick={handleResendOTP} disableRipple>
        RESEND
      </Button>
    </div>
  );
};

export default OtpButtons;

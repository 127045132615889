/* eslint-disable react/prop-types */
import { FileDownload, Search, Visibility } from '@mui/icons-material';
import {
  CircularProgress,
  debounce,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {
  GridPaginationModel,
  GridRowParams,
  GridSortItem,
} from '@mui/x-data-grid';
import {
  ADMIN_STATUS,
  CREATIVE_STATUS,
  CreativeRequest,
  ENTITIES,
  SEARCH_USERS_RESPONSE,
  SortOrder,
  USER_TYPES,
  UserProfile,
} from 'API';
import { downloadFileFromUrl } from 'components';
import Modal from 'components/authentication/modal';
import CreatorStatsCard from 'components/creatorStatsCard/creatorStatsCard';
import { StyledDataGrid } from 'components/DataGrid';
import GradientCard from 'components/gradientCard/gradientCard';
import { StyledTextField } from 'components/TextField';
import { endOfDay, isWithinInterval, startOfDay, subDays } from 'date-fns';
import {
  UseExportCreativeUsers,
  UseExportEntitiesToCSV,
  UseGetUsersSignUps,
  UseSearchCreativeUsers
} from 'hooks/query/useAdminActions';
import {
  DATA_GRID_ROW_OPTIONS,
  DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORTING_MODEL,
  QueryPayload,
} from 'hooks/utils';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAdmin } from 'state/admin';
import { AdminDashboardBoxes, AdminRoutes } from 'utils';
import { HEADER_CELLS } from './constants';
import EditUser from './editUser';
import UserBankDetails from './userBankDetails';

type UserBankDetails = {
  id: string;
  accountNumber: string;
  country: string;
  createdAt: string;
  documentID: string;
  firstAddress: string;
  fullName: string;
  secondAddress: string;
  swiftCode: string;
};

const getFormattedData = (users: SEARCH_USERS_RESPONSE[]) =>
  users.map((u) => ({
    ...u,
    img: u.avatar || '/images/default-image.png',
    uniqueId: u.uniqueId,
    name: u.name,
    email: u.email,
    phoneNumber: u.phoneNumber || 'N/A',
    lastLoginDate: u.lastLoginDate
      ? new Date(u.lastLoginDate).toLocaleDateString()
      : 'N/A',
    userPaymentDetails: u.userPaymentDetails,
  }));

const getApprovedPercentage = (requests?: CreativeRequest[]) => {
  const approvedCount = (requests || []).reduce(
    (a, cr) =>
      a +
      Number(
        cr?.adminApproval === ADMIN_STATUS.Approved &&
        cr?.status === CREATIVE_STATUS.Approved
      ),
    0
  );

  return approvedCount
    ? Math.floor((approvedCount / Number(requests?.length)) * 100)
    : approvedCount;
};

const getActiveCreativeRequestsInRange = (requests?: CreativeRequest[]) => {
  const currentDate = new Date();
  const start = startOfDay(subDays(currentDate, 1));
  const end = endOfDay(currentDate);

  return (requests || [])?.filter(
    (cr) =>
      cr.updatedAt &&
      cr.adminApproval === ADMIN_STATUS.Approved &&
      cr.status === CREATIVE_STATUS.Approved &&
      isWithinInterval(cr.updatedAt || '', { start, end })
  ).length;
};

const AdminCreativeUsers = ({ creativeRequestsData }) => {
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [sortingModel, setSortingModel] = useState(DEFAULT_SORTING_MODEL);
  const [isMissingBankDetailsModalOpen, setIsMissingBankDetailsModalOpen] =
    useState(false);
  const {
    exportCreativeUsers,
    data: exportData,
    loading: creativeUsersExportLoading,
  } = UseExportCreativeUsers();

  const {
    exportEntities,
    url: entitiesExportUrl,
    loading: entitiesExportLoading,
  } = UseExportEntitiesToCSV();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [rowsCount, setRowsCount] = useState(0);
  const [search, setSearch] = useState('');
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [tableData, setTableData] = useState<unknown[]>([]);
  const { getSignups, signUps } = UseGetUsersSignUps()
  const { searchCreativeUsers, creativeUsers, totalItems, loading } =
    UseSearchCreativeUsers();

  useLayoutEffect(() => {
    getSignups({ variables: { userType: USER_TYPES.CREATIVE_USER, fromDate: subDays(new Date(), 1).toISOString(), }, errorPolicy: "ignore" })
    getCreativeUsers({ page: 0, pageSize: DEFAULT_PAGE_SIZE });
  }, []);

  const creatorSignUps = useMemo(
    () => `${signUps || 0}`,
    [signUps]
  );
  const approvedCreativesCount = useMemo(
    () => `${getApprovedPercentage(creativeRequestsData)}%`,
    [creativeRequestsData]
  );

  const activeCreativeRequestsCount = useMemo(
    () => `${getActiveCreativeRequestsInRange(creativeRequestsData)}`,
    [creativeRequestsData]
  );

  const getCreativeUsers = ({
    page,
    pageSize,
    search,
    ...sorting
  }: QueryPayload) => {
    searchCreativeUsers({
      variables: {
        page: page || 0,
        pageSize: pageSize || 10,
        search,
        ...sorting,
      },
      refetchWritePolicy: 'merge',
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    });
  };

  const onDetails = (user: UserProfile) => {
    setSelectedUser(user);
  };

  const onBankDetails = (params) => {
    if (!params.value?.id) {
      setIsMissingBankDetailsModalOpen(true);
      return;
    }

    setSelectedUser(params.row);
    setShowBankDetails((prev) => !prev);
    navigate(`${AdminRoutes.Creators}?bankView=true&id=${params.value?.id}`);
  };

  const tableHead = useMemo(
    () =>
      HEADER_CELLS.map((c) => {
        switch (c.field) {
          case 'details':
            return {
              ...c,
              renderCell: (params) => (
                <IconButton
                  onClick={() => {
                    onDetails(params.row);
                  }}
                >
                  <Visibility />
                </IconButton>
              ),
            };
          case 'userPaymentDetails':
            return {
              ...c,
              renderCell: (params) => {
                return (
                  <div className="flex justify-center items-center h-full">
                    <div
                      onClick={(e) => {
                        onBankDetails(params);
                      }}
                      className={`${params.value?.id
                        ? 'text-white bg-main-black'
                        : 'border-2 border-[#1d1d1d]'
                        }  flex justify-center rounded-[20px] leading-[20px] h-[21px] px-[16px] font-[400] text-[12px]`}
                    >
                      {params.value?.id ? 'Complete' : 'In-complete'}
                    </div>
                  </div>
                );
              },
            };
          default:
            return c;
        }
      }),
    []
  );

  const onReset = () => {
    setSelectedUser(null);
    setIsUsersLoading(false);
    setPage(0);
    setPageSize(DEFAULT_PAGE_SIZE);
  };

  const onSortModelChange = (model?: GridSortItem) => {
    if (!model) {
      return;
    }

    const updatedSortModel = {
      sortKey: model.field || sortingModel.sortKey,
      sortOrder: (model.sort || sortingModel.sortOrder) as SortOrder,
    };

    setSortingModel(updatedSortModel);
    getCreativeUsers({
      page,
      pageSize,
      search: search,
      ...updatedSortModel,
    });
  };

  const onSearch = debounce(async (value: string) => {
    onReset();
    setPage(0);
    setIsUsersLoading(true);
    await searchCreativeUsers({
      variables: { page, pageSize, search: value.trim() },
    });

    setIsUsersLoading(false);
    setSearch(value);
  }, DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS);

  const onExportUserProfileContent = () => {
    exportCreativeUsers({});
  };

  const onExportUsers = () => {
    exportEntities({ variables: { entity: ENTITIES.CreativeUsers } });
  };

  useEffect(() => {
    if (exportData?.exportUsers) {
      downloadFileFromUrl(exportData.exportUsers.url);
    }

    if (entitiesExportUrl) {
      downloadFileFromUrl(entitiesExportUrl);
    }
  }, [exportData, entitiesExportUrl]);

  const onPageChange = (model: GridPaginationModel) => {
    const payload =
      model.pageSize !== pageSize
        ? { page: 0, pageSize: model.pageSize }
        : { page: model.page, pageSize };

    setPage(payload.page);
    setPageSize(payload.pageSize);
    return getCreativeUsers({
      ...payload,
      ...(search && { search }),
      ...sortingModel,
    });
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');
    const bankView = url.searchParams.get('bankView');
    setShowBankDetails(false);
    if (id && creativeUsers) {
      const user = creativeUsers?.find(
        (brief) => brief?.id === id
      ) as unknown as UserProfile;

      if (!user) navigate(`${AdminRoutes.Creators}`);
      else if (user && user.userPaymentDetails && bankView) {
        setSelectedUser(user);
        setShowBankDetails(true);
      } else if (user && !user.userPaymentDetails && bankView) {
        setIsMissingBankDetailsModalOpen(true);
        navigate(`${AdminRoutes.Creators}`);
      } else {
        setSelectedUser(user);
      }
    } else {
      setSelectedUser(null);
    }
  }, [window.location.href, creativeUsers]);

  useEffect(() => {
    if (creativeUsers.length) {
      const formattedUserData = getFormattedData(creativeUsers);
      setTableData(formattedUserData);
    }

    if (totalItems) {
      setRowsCount(totalItems);
    }
  }, [creativeUsers, totalItems]);

  return selectedUser && !showBankDetails ? (
    <EditUser user={selectedUser as unknown as UserProfile} onReset={onReset} />
  ) : selectedUser && showBankDetails ? (
    <UserBankDetails
      bankDetails={selectedUser.userPaymentDetails as UserBankDetails}
      userId={selectedUser.id}
    />
  ) : (
    <>
      <div className="creative-requests py-0">
        <div className="lg:grid flex grid-cols-4 lg:gap-[20px] gap-[10px] mb-[20px] w-full lg:overflow-x-visible overflow-x-auto overflow-y-hidden creator-dashboard p-0 lg:pb-0 pb-[4px]">
          <GradientCard>
            <CreatorStatsCard
              type={AdminDashboardBoxes.CreatorSignUps}
              value={creatorSignUps}
            />
          </GradientCard>
          <GradientCard>
            <CreatorStatsCard
              type={AdminDashboardBoxes.ContentSubmissionsByCreators}
              value={`${creativeRequestsData?.length || 0}`}
            />
          </GradientCard>
          <GradientCard>
            <CreatorStatsCard
              type={AdminDashboardBoxes.ContentApprovals}
              value={approvedCreativesCount}
            />
          </GradientCard>
          <GradientCard>
            <CreatorStatsCard
              type={AdminDashboardBoxes.CurrentActiveCreative}
              value={activeCreativeRequestsCount}
            />
          </GradientCard>
        </div>

        <section className="flex-col h-full mt-4">
          <div className="mt-10 mr-2 flex justify-between align-center w-full">
            <div>
              <StyledTextField
                variant="standard"
                placeholder="Search..."
                onChange={(event) => {
                  onSearch(event?.target.value);
                }}
                fullWidth={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="flex flex-between align-center gap-x-1">
              <button
                className="bg-main-black text-white px-[22px] py-[4] w-[200px] h-[42px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
                disabled={!rowsCount}
                onClick={onExportUserProfileContent}
              >
                <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                  {creativeUsersExportLoading ? (
                    <CircularProgress className="text-white w-6 h-6" />
                  ) : (
                    <>
                      <FileDownload />
                      <div>creator portfolio</div>
                    </>
                  )}
                </div>
              </button>

              <button
                className="bg-main-black text-white px-[22px] py-[4] w-[200px] h-[42px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
                disabled={!rowsCount}
                onClick={onExportUsers}
              >
                <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                  {entitiesExportLoading ? (
                    <CircularProgress className="text-white w-6 h-6" />
                  ) : (
                    <>
                      <FileDownload />
                      <div>Users</div>
                    </>
                  )}
                </div>
              </button>
            </div>
          </div>

          <div className="overflow-x-auto max-w-full ">
            <StyledDataGrid
              columns={tableHead}
              // FIXME:
              // @ts-expect-error
              rows={tableData || []}
              onRowClick={(params: GridRowParams) => {
                onDetails(params.row);
              }}
              density="comfortable"
              loading={loading || isUsersLoading || !tableData}
              paginationModel={{ page, pageSize }}
              pageSizeOptions={DATA_GRID_ROW_OPTIONS}
              paginationMode="server"
              sortingMode="server"
              onSortModelChange={(model) => onSortModelChange(model?.[0])}
              rowCount={rowsCount}
              initialState={{
                pagination: { paginationModel: { page, pageSize } },
              }}
              onPaginationModelChange={onPageChange}
              disableRowSelectionOnClick={true}
              disableColumnResize={true}
              disableAutosize={true}
              disableColumnMenu={true}
              autosizeOnMount={true}
              disableColumnSelector={true}
              autosizeOptions={{
                expand: true,
                includeHeaders: true,
                includeOutliers: true,
              }}
            />
          </div>
        </section>
        <Modal
          isOpen={showSuccessModal}
          handleClose={() => setShowSuccessModal(false)}
          type="brand"
          content="The creator of the creative request was successfully changed"
        />
        <Modal
          isOpen={isMissingBankDetailsModalOpen}
          handleClose={() => setIsMissingBankDetailsModalOpen(false)}
          type="brand"
          content="This user doesn't have bank details"
          actionLabel=""
          withOutLabel={true}
        />
      </div>
    </>
  );
};

export default withAdmin(AdminCreativeUsers);

import { BrandBrief, UserProfile } from 'API';
import CreatorNotifications from 'components/creatorNotifications/creatorNotifications';
import CreatorStatsCard from 'components/creatorStatsCard/creatorStatsCard';
import GradientCard from 'components/gradientCard/gradientCard';
import Table, { Tdata } from 'components/table/Table';
import CampaignBriefDetails from 'pages/campaignBriefDetails/campaignBriefDetails';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICreatorBriefListProps, withCreatorBriefList } from 'state/dashboard';
import { AuthRoutes, CreatorDashboardBoxes, CreatorRoutes } from 'utils';
import { UseGetCreativeEarnings } from '../../hooks';
import './creatorDashboard.css';

const BRAND_BRIEF_TABLE_PAGE_SIZE = 10;

function CreatorDashboard({
  data: profileData,
  loading,
  requestLoading,
  briefList,
  requestList,
  currentPage,
  profileCompletionPercentage,
  briefPagination,
  changeBriefPage,
}: ICreatorBriefListProps & { data: UserProfile }) {
  const [selectedBrief, setSelectedBrief] = useState<BrandBrief>();
  const [briefsTableData, setBriefsTableData] = useState<Tdata[] | null>(null);
  const [requestsTableData, setRequestsTableData] = useState<Tdata[] | null>(
    null
  );
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [previousMonthEarnings, setPreviousMonthEarnings] = useState(0);

  const navigate = useNavigate();

  const {
    getEarnings,
    data: earningsData,
    loading: earningsLoading,
  } = UseGetCreativeEarnings();

  const isLoading = loading || requestLoading || earningsLoading;

  const checkParam = () => {
    const url = new URL(window.location.href);
    return !!url.searchParams.get('brief');
  };

  useEffect(() => {
    getEarnings({ variables: { creatorId: profileData.id } });
    if (!selectedBrief && checkParam()) navigate(AuthRoutes.Dashboard);
  }, []);

  const calculateEarnings = (earnings) =>
    earnings.reduce(
      (a, e) => ({
        lifetime: a.lifetime + e.lifetimeEarnings,
        current: a.current + e.currentEarnings,
        previous: a?.previous + e.previousEarnings || 0,
      }),
      {
        lifetime: 0,
        current: 0,
        previous: 0,
      }
    );

  useEffect(() => {
    if (!earningsData) {
      return;
    }

    const calculated = calculateEarnings(earningsData);
    setCurrentEarnings(calculated.current);
    setTotalEarnings(calculated.lifetime);
    setPreviousMonthEarnings(calculated.previous);
  }, [earningsData]);

  useEffect(() => {
    const sortedData = [...(briefList || [])].sort(
      (a, b) => Number(b?.active) - Number(a?.active)
    );

    const tableData = sortedData.map((brief) => ({
      id: brief?.id || '',
      img: brief?.brandInfo?.userProfile?.avatar || '/images/default-image.png',
      activationName: brief?.BriefName,
      brandName: brief?.brandInfo?.userProfile?.name || 'N/A',
      status: brief?.active ? 'Active' : 'In-active',
    }));

    setBriefsTableData(tableData);
  }, [briefList]);

  useEffect(() => {
    setRequestsTableData(
      [...requestList]?.splice(0, 10).map((req) => ({
        id: req?.id || '',
        activationName: req?.briefName,
        creativeId: req?.uniqueId,
        status: req?.status,
      }))
    );
  }, [requestList]);

  if (selectedBrief && checkParam()) {
    const brandProfile = selectedBrief['brandInfo'];
    const hashtags = brandProfile?.hashtags?.length
      ? brandProfile?.hashtags
      : brandProfile?.userProfile?.hashtags || [];

    const description =
      brandProfile?.personalDescription ||
      brandProfile?.userProfile?.description ||
      '';
    return (
      <CampaignBriefDetails
        data={selectedBrief}
        hashtags={hashtags}
        description={description}
        userType={profileData.userType as string}
        onBack={(): void => {
          setSelectedBrief(undefined);
          navigate(AuthRoutes.Dashboard);
        }}
      />
    );
  }

  return (
    <div className="flex flex-col gap-y-[30px] ">
      <div className="lg:grid flex grid-cols-4 gap-[30px] w-full lg:overflow-x-visible overflow-x-auto overflow-y-hidden creator-dashboard p-0 lg:pb-0 pb-[4px]">
        <GradientCard>
          <CreatorStatsCard
            type={CreatorDashboardBoxes.Profile}
            value={`${profileCompletionPercentage || 0}%`}
          />
        </GradientCard>

        <GradientCard>
          <CreatorStatsCard
            type={CreatorDashboardBoxes.Earnings}
            value={`$${currentEarnings.toFixed(2)}`}
          />
        </GradientCard>

        <GradientCard>
          <CreatorStatsCard
            type={CreatorDashboardBoxes.PreviousMonthEarnings}
            value={`$${previousMonthEarnings.toFixed(2)}`}
          />
        </GradientCard>

        <GradientCard>
          <CreatorStatsCard
            type={CreatorDashboardBoxes.ClickThrough}
            value={`$${totalEarnings.toFixed(2)}`}
          />
        </GradientCard>
      </div>

      <div className="grid lg:grid-cols-2 gap-x-[30px]">
        <div>
          <Table
            mainlyData={briefList as Tdata[]}
            data={briefsTableData as Tdata[]}
            rows={['', 'brandName', 'activationName', 'status', 'details']}
            onRowClick={(brief) => {
              setSelectedBrief(brief);
              if (brief.active) navigate(`${CreatorRoutes.BriefDetails}?id=${brief.id}`);
            }}
            extended={false}
            extendedURL={AuthRoutes.BrandBrief}
            loading={isLoading}
            rowWidth="w-[110px]"
            borderColor="#FF872F"
            firstRowName="BRAND"
            pagination={briefPagination || 0}
            changePage={changeBriefPage}
            pageSize={BRAND_BRIEF_TABLE_PAGE_SIZE}
            tableCurrentPage={Number(currentPage || 1)}
            tableContentHeight="lg:h-[740px] h-[360px]"
            tableHeight="lg:h-[664px] h-[285px]"
            rowHeight="h-[60px]"
          />
        </div>

        <div className="grid lg:grid-cols-1 gap-[20px]">
          <Table
            mainlyData={requestList || []}
            data={requestsTableData as Tdata[]}
            extended={false}
            extendedURL={CreatorRoutes.Creatives}
            rows={['creativeId', 'activationName', 'status', 'details']}
            onRowClick={() => {
              navigate(`${CreatorRoutes.Creatives}`);
            }}
            pagination={0}
            loading={requestLoading}
            rowWidth="w-[110px]"
            borderColor="#FF872F"
            firstRowName="Creatives"
          />
          <CreatorNotifications />
        </div>
      </div>
    </div>
  );
}

export default withCreatorBriefList(CreatorDashboard);

import { CreativeRequest, CREATIVE_STATUS } from 'API';

export const getStatusColor = (status: CREATIVE_STATUS) => {
  switch (status) {
    case CREATIVE_STATUS.Approved:
      return 'bg-[#00B1B5]';
    case CREATIVE_STATUS.Rejected:
      return 'bg-main-dark-red';
    default:
      return 'bg-[#FF872F]';
  }
};

export const getCreativePreviewUrl = (creativeRequest: CreativeRequest) => {
  const path = creativeRequest.tiktokCreativeUrl.split('/').join('!');
  return `/preview/${path}/?uniqueId=${creativeRequest.uniqueId}&brandImage=${creativeRequest.brief?.brandProfile?.userProfile?.avatar}&briefName=${creativeRequest.brief?.BriefName}&isBrandView=true&creativeRequestId=${creativeRequest.id}`;
};

export const getStatusLabel = ({
  status,
  containerClassnames,
  wrapperClassnames,
  statusClassNames,
}: Record<string, any>) => (
  <div
    className={`p-2 h-full flex flex-col justify-center items-center ${containerClassnames}`}
  >
    <div
      className={`flex justify-center ${getStatusColor(
        status
      )} text-sm rbg-slate-900 text-white max-h-[50px] rounded-[16px] text-center align-middle py-1 px-6 sm:text-xs ${wrapperClassnames}`}
    >
      <p className={`${statusClassNames}`}>
        {status === CREATIVE_STATUS.Approved ||
        status === CREATIVE_STATUS.Rejected
          ? status
          : 'Awaiting Approval'}
      </p>
    </div>
  </div>
);

export const requestsSortComparator = (v1, v2, p1, p2) => {
  if (v1 === CREATIVE_STATUS.Approved && v2 === CREATIVE_STATUS.Submitted) {
    return -1;
  }

  if (v1 === CREATIVE_STATUS.Submitted && v2 === CREATIVE_STATUS.Approved) {
    return 1;
  }

  return 0;
};

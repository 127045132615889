import { Add, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useMemo } from 'react';
import { HEADER_CELLS } from './constants';

interface ITableHeaderProps {
  onAddEarning: () => void;
  onUpdateEarning: (earning) => void;
}

const TableHead = ({ onAddEarning, onUpdateEarning }: ITableHeaderProps) =>
  useMemo(
    () =>
      HEADER_CELLS.map((c) => {
        switch (c.field) {
          case 'edit':
            return {
              ...c,
              renderCell: ({ row }) => (
                <IconButton onClick={() => onUpdateEarning(row)}>
                  <Edit />
                </IconButton>
              ),
            };
          case 'add':
            return {
              ...c,
              renderCell: () => (
                <IconButton onClick={() => onAddEarning()}>
                  <Add />
                </IconButton>
              ),
            };
          default:
            return c;
        }
      }),
    []
  );

export default TableHead;

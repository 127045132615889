import { useState, useEffect } from 'react';
const screens = {
    'sm': '640px',
    'md': '768px',
    'lg': '1024px',
    'xl': '1280px',
    '2xl': '1536px',
}
const useBreakpoints = () => {
    const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint());

    function getCurrentBreakpoint() {
        const width = window.innerWidth;
        const breakpoints = Object.entries(screens).map(([key, value]) => ({
            name: key,
            value: parseInt(value),
        }));

        const sortedBreakpoints = breakpoints.sort((a, b) => a.value - b.value);
        const currentBreakpoint = sortedBreakpoints.find(breakpoint => width <= breakpoint.value);

        return currentBreakpoint ? currentBreakpoint.name : 'default';
    }

    useEffect(() => {
        const handleResize = () => {
            setBreakpoint(getCurrentBreakpoint());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return breakpoint;
};

export default useBreakpoints;
import { CreativeRequest } from 'API';
import { FC } from 'react';
import { withAdmin } from 'state/admin';
import './adminCreativeApproval.css';
import TableTab from './components/TableTab';

interface Props {
  updateRequest;
  reqloading: boolean;
  creativeRequestsData: CreativeRequest[];
  sortCreativeRequest: (sort: string) => void;
}

const AdminCreativeApproval: FC<Props> = (props) => {
  return (
    <div>
      <TableTab {...props} />
    </div>
  );
};

export default withAdmin(AdminCreativeApproval);

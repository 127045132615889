import { Search } from '@mui/icons-material';
import { Button, InputAdornment } from '@mui/material';
import { BrandBrief, ModelSortDirection } from 'API';
import AdminBriefDetails from 'components/AdminBriefDetails/AdminBriefDetails';
import Modal from 'components/authentication/modal';
import { StyledDataGrid } from 'components/DataGrid';
import { StyledTextField } from 'components/TextField';
import { editCampaignBrief, UseSendBriefCreationNotification } from 'hooks';
import {
  DATA_GRID_ROW_OPTIONS,
  DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS,
} from 'hooks/utils';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { withAdmin } from 'state/admin';
import { AdminRoutes } from 'utils';
import ActivationModal from './components/ActivationModal/ActivationModal';
import CreatorNotificationModal from './components/CreatorNotificationModal/CreatorNotificationModal';
import TableHead from './components/TableHead/TableHead';
import EditBrief from './editBrief';

const AdminBrandBriefs = ({
  brandBriefs,
  sortBrandBriefs,
  isListBriefsLoading,
  brandOptions,
  createBriefWithBrandUserProfileId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredData, setFilteredData] = useState<BrandBrief[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedBrief, setSelectedBrief] = useState<BrandBrief | null>(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { editBrief, loading: updateLoading } = editCampaignBrief();
  const [isAddBriefModalOpen, setIsAddBriefModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const { sendNotification, loading: isSendNotificationLoading } =
    UseSendBriefCreationNotification();

  useEffect(() => {
    if (!location.search.includes('isDetails') && isDetailsOpen) {
      setSelectedBrief(null);
      setIsDetailsOpen(false);
    }

    if (
      !location.search.includes('id') &&
      selectedBrief &&
      !isDetailsOpen &&
      !isNotificationModalOpen
    ) {
      setSelectedBrief(null);
    }
  }, [location.search, isDetailsOpen, selectedBrief, isNotificationModalOpen]);

  const onDetails = (brandBrief: BrandBrief) => {
    navigate(
      `${AdminRoutes.BrandBriefs}?isDetails=true&briefId=${brandBrief.id}`
    );
    setSelectedBrief(brandBrief);
    setIsDetailsOpen(true);
  };

  const onEditBrief = (brandBrief: BrandBrief) => {
    navigate(
      `${AdminRoutes.CreateBrandActivation}?brandId=${brandBrief.brandId}&briefId=${brandBrief.id}`
    );
  };

  const onBriefStatusChange = async (brief: BrandBrief) => {
    const update = debounce(async () => {
      await editBrief({
        variables: { input: { id: brief.id, active: !brief.active } },
        errorPolicy: 'ignore',
      });

      toast.success('Brief status updated successfully');
      await sortBrandBriefs('DESC');
    }, 200);

    return update();
  };

  const onNotificationIconClick = async (brief: BrandBrief) => {
    setIsNotificationModalOpen(true);
    setSelectedBrief(brief);
  };

  const tableHead = TableHead({
    onBriefStatusChange,
    onDetails,
    onNotificationIconClick,
    onEditBrief,
  });

  const onSearch = debounce((text: string) => {
    if (!text.trim()) {
      setFilteredData(brandBriefs);
    } else {
      const filterData: BrandBrief[] = [];
      console.log(brandBriefs);
      for (const data of brandBriefs) {
        const briefStatus = data.active ? 'active' : 'ended';
        if (
          (data.brandName &&
            data.brandName.toLowerCase().includes(text.toLowerCase())) ||
          (data.createdAt &&
            String(
              new Date(data?.createdAt || '').toLocaleString().split(',')[0]
            ).includes(text.toLowerCase())) ||
          (data.BriefName &&
            data.BriefName.toLowerCase().includes(text.toLowerCase())) ||
          (data.vertical &&
            data.vertical
              .replaceAll('-', ' ')
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (data.objective &&
            data.objective.toLowerCase().includes(text.toLowerCase())) ||
          (data.brandName &&
            data.brandName.toLowerCase().includes(text.toLowerCase())) ||
          briefStatus.toLowerCase().includes(text.toLowerCase())
        ) {
          filterData.push(data);
        }
      }
      setFilteredData(filterData);
    }
  }, DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS);

  useEffect(() => {
    if (brandBriefs) {
      setFilteredData(brandBriefs);
    }
  }, [brandBriefs]);

  const onReset = () => {
    setSelectedBrief(null);
    setIsDetailsOpen(false);
    setIsNotificationModalOpen(false);
    setIsAddBriefModalOpen(false);
    navigate(AdminRoutes.BrandBriefs);
  };

  const sendBrandBriefCreationNotification = useCallback(
    async (brandBrief?: BrandBrief | null) => {
      if (!brandBrief?.id) {
        return;
      }

      await sendNotification({ variables: { brandBriefId: brandBrief.id } });
      await sortBrandBriefs(ModelSortDirection.DESC);
      onReset();
    },
    [onReset, selectedBrief, sendNotification, sortBrandBriefs]
  );

  const onSendBrandActivation = async (userProfileId: string) => {
    setIsAddBriefModalOpen(false);
    const { id, brandId } = await createBriefWithBrandUserProfileId(
      userProfileId
    );

    navigate(
      `${AdminRoutes.CreateBrandActivation}?brand=${brandId}&briefId=${id}`
    );
  };

  const onCloseAddActivationModal = () => {
    setIsAddBriefModalOpen(false);
  };

  return selectedBrief && !isNotificationModalOpen ? (
    !isDetailsOpen ? (
      <EditBrief brief={selectedBrief} onClose={onReset} />
    ) : (
      <AdminBriefDetails
        getBrandBriefs={() => sortBrandBriefs('DESC')}
        brandBrief={selectedBrief}
        onBack={onReset}
      />
    )
  ) : (
    <>
      <div className="creative-requests py-0">
        <div className="mt-2 flex flex-col gap-y-6">
          <div className="flex justify-between items-center">
            <StyledTextField
              sx={{ maxWidth: 250 }}
              variant="standard"
              placeholder="Search..."
              onChange={(event) => {
                onSearch(event?.target.value);
              }}
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              disableRipple={true}
              className="bg-main-black text-white px-[22px] py-[8px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
              onClick={() => setIsAddBriefModalOpen(true)}
            >
              <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                <div>Add Activation</div>
              </div>
            </Button>
          </div>

          <StyledDataGrid
            rows={filteredData || []}
            sx={{ minHeight: 600 }}
            density="comfortable"
            columns={tableHead}
            pageSizeOptions={DATA_GRID_ROW_OPTIONS}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            loading={isListBriefsLoading || updateLoading || !filteredData}
            paginationMode="client"
            autosizeOptions={{
              expand: true,
              includeHeaders: true,
              includeOutliers: true,
            }}
            disableColumnResize={true}
            disableRowSelectionOnClick={true}
            disableAutosize={true}
            disableColumnMenu={true}
            autosizeOnMount={true}
            disableColumnSelector={true}
          />
        </div>

        <div className="brand-table-wrapper h-[auto]">
          <Modal
            isOpen={showSuccessModal}
            handleClose={() => setShowSuccessModal(false)}
            type="brand"
            content="The creator of the creative request was successfully changed"
          />
        </div>
      </div>

      {selectedBrief && isNotificationModalOpen && (
        <CreatorNotificationModal
          isLoading={isSendNotificationLoading}
          onCancel={onReset}
          brandBrief={selectedBrief}
          onSend={sendBrandBriefCreationNotification}
        />
      )}

      <ActivationModal
        title="Create brand activation"
        onCancel={onCloseAddActivationModal}
        onSend={onSendBrandActivation}
        isOpen={isAddBriefModalOpen}
        options={brandOptions}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      ></ToastContainer>
    </>
  );
};

export default withAdmin(AdminBrandBriefs);

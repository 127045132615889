/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const creativeRequestUniqueId = /* GraphQL */ `mutation CreativeRequestUniqueId(
  $brandBriefId: String
  $creativeRequestId: String
) {
  creativeRequestUniqueId(
    brandBriefId: $brandBriefId
    creativeRequestId: $creativeRequestId
  )
}
` as GeneratedMutation<
  APITypes.CreativeRequestUniqueIdMutationVariables,
  APITypes.CreativeRequestUniqueIdMutation
>;
export const videoPreviewUrl = /* GraphQL */ `mutation VideoPreviewUrl($videoPath: String) {
  videoPreviewUrl(videoPath: $videoPath)
}
` as GeneratedMutation<
  APITypes.VideoPreviewUrlMutationVariables,
  APITypes.VideoPreviewUrlMutation
>;
export const creativeRequestAuthorization = /* GraphQL */ `mutation CreativeRequestAuthorization(
  $creativeRequestId: String
  $brandBriefId: String
  $advertiser_id: String
  $tiktokVideoCode: String
) {
  creativeRequestAuthorization(
    creativeRequestId: $creativeRequestId
    brandBriefId: $brandBriefId
    advertiser_id: $advertiser_id
    tiktokVideoCode: $tiktokVideoCode
  )
}
` as GeneratedMutation<
  APITypes.CreativeRequestAuthorizationMutationVariables,
  APITypes.CreativeRequestAuthorizationMutation
>;
export const linkTiktokAccount = /* GraphQL */ `mutation LinkTiktokAccount($authCode: String, $userProfileId: String) {
  linkTiktokAccount(authCode: $authCode, userProfileId: $userProfileId)
}
` as GeneratedMutation<
  APITypes.LinkTiktokAccountMutationVariables,
  APITypes.LinkTiktokAccountMutation
>;
export const linkCreatorTikTokAccount = /* GraphQL */ `mutation LinkCreatorTikTokAccount($authCode: String, $userProfileId: String) {
  linkCreatorTikTokAccount(authCode: $authCode, userProfileId: $userProfileId)
}
` as GeneratedMutation<
  APITypes.LinkCreatorTikTokAccountMutationVariables,
  APITypes.LinkCreatorTikTokAccountMutation
>;
export const linkCreatorInstagramAccount = /* GraphQL */ `mutation LinkCreatorInstagramAccount(
  $authCode: String
  $userProfileId: String
) {
  linkCreatorInstagramAccount(
    authCode: $authCode
    userProfileId: $userProfileId
  )
}
` as GeneratedMutation<
  APITypes.LinkCreatorInstagramAccountMutationVariables,
  APITypes.LinkCreatorInstagramAccountMutation
>;
export const linkFacebookAccount = /* GraphQL */ `mutation LinkFacebookAccount($authCode: String, $userProfileId: String) {
  linkFacebookAccount(authCode: $authCode, userProfileId: $userProfileId)
}
` as GeneratedMutation<
  APITypes.LinkFacebookAccountMutationVariables,
  APITypes.LinkFacebookAccountMutation
>;
export const linkYoutubeAccount = /* GraphQL */ `mutation LinkYoutubeAccount($authCode: String, $userProfileId: String) {
  linkYoutubeAccount(authCode: $authCode, userProfileId: $userProfileId)
}
` as GeneratedMutation<
  APITypes.LinkYoutubeAccountMutationVariables,
  APITypes.LinkYoutubeAccountMutation
>;
export const linkCreatorYoutubeAccount = /* GraphQL */ `mutation LinkCreatorYoutubeAccount($authCode: String, $userProfileId: String) {
  linkCreatorYoutubeAccount(authCode: $authCode, userProfileId: $userProfileId)
}
` as GeneratedMutation<
  APITypes.LinkCreatorYoutubeAccountMutationVariables,
  APITypes.LinkCreatorYoutubeAccountMutation
>;
export const validateTiktokAccess = /* GraphQL */ `mutation ValidateTiktokAccess($accessToken: String) {
  validateTiktokAccess(accessToken: $accessToken)
}
` as GeneratedMutation<
  APITypes.ValidateTiktokAccessMutationVariables,
  APITypes.ValidateTiktokAccessMutation
>;
export const linkUserType = /* GraphQL */ `mutation LinkUserType($userType: String, $profileId: String) {
  linkUserType(userType: $userType, profileId: $profileId)
}
` as GeneratedMutation<
  APITypes.LinkUserTypeMutationVariables,
  APITypes.LinkUserTypeMutation
>;
export const createAds = /* GraphQL */ `mutation CreateAds(
  $token: String
  $authCode: String
  $advId: String
  $adgroupId: String
  $landingPageUrl: String
  $identityId: String
  $displayName: String
  $videoUrl: String
  $callToAction: String
  $creativeRequestId: String
  $adName: String
  $creatorId: String
  $adCaption: String
) {
  createAds(
    token: $token
    authCode: $authCode
    advId: $advId
    adgroupId: $adgroupId
    landingPageUrl: $landingPageUrl
    identityId: $identityId
    displayName: $displayName
    videoUrl: $videoUrl
    callToAction: $callToAction
    creativeRequestId: $creativeRequestId
    adName: $adName
    creatorId: $creatorId
    adCaption: $adCaption
  )
}
` as GeneratedMutation<
  APITypes.CreateAdsMutationVariables,
  APITypes.CreateAdsMutation
>;
export const createMetaAd = /* GraphQL */ `mutation CreateMetaAd(
  $accessToken: String!
  $accountId: String!
  $adName: String!
  $adSetId: String!
  $campaignId: String!
  $videoUrl: String!
  $pageId: String
  $creativeRequestId: String!
) {
  createMetaAd(
    accessToken: $accessToken
    accountId: $accountId
    adName: $adName
    adSetId: $adSetId
    campaignId: $campaignId
    videoUrl: $videoUrl
    pageId: $pageId
    creativeRequestId: $creativeRequestId
  )
}
` as GeneratedMutation<
  APITypes.CreateMetaAdMutationVariables,
  APITypes.CreateMetaAdMutation
>;
export const createManualAd = /* GraphQL */ `mutation CreateManualAd($creativeRequestId: String!) {
  createManualAd(creativeRequestId: $creativeRequestId)
}
` as GeneratedMutation<
  APITypes.CreateManualAdMutationVariables,
  APITypes.CreateManualAdMutation
>;
export const listAdGroups = /* GraphQL */ `mutation ListAdGroups(
  $token: String
  $advertiser_id: String
  $campaignId: String
) {
  listAdGroups(
    token: $token
    advertiser_id: $advertiser_id
    campaignId: $campaignId
  )
}
` as GeneratedMutation<
  APITypes.ListAdGroupsMutationVariables,
  APITypes.ListAdGroupsMutation
>;
export const listCampaigns = /* GraphQL */ `mutation ListCampaigns($token: String, $advertiser_id: String) {
  listCampaigns(token: $token, advertiser_id: $advertiser_id) {
    campaigns {
      advertiser_id
      campaign_id
      campaign_system_origin
      create_time
      modify_time
      objective_type
      app_promotion_type
      is_search_campaign
      is_smart_performance_campaign
      campaign_type
      app_id
      is_advanced_dedicated_campaign
      campaign_app_profile_page_state
      rf_campaign_type
      campaign_product_source
      campaign_name
      special_industries
      budget_optimize_on
      bid_type
      depp_bid_type
      roas_bid
      optimization_goal
      budget_mode
      budget
      operation_status
      secondary_status
      postback_window_mode
      is_new_structure
      objective
      __typename
    }
    identities {
      identity_id
      identity_type
      identity_authorized_bc_id
      can_push_video
      can_use_live_ads
      can_pull_video
      display_name
      profile_image
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ListCampaignsMutationVariables,
  APITypes.ListCampaignsMutation
>;
export const getFacebookCampaign = /* GraphQL */ `mutation GetFacebookCampaign($access_token: String, $advertiser_id: String) {
  getFacebookCampaign(
    access_token: $access_token
    advertiser_id: $advertiser_id
  )
}
` as GeneratedMutation<
  APITypes.GetFacebookCampaignMutationVariables,
  APITypes.GetFacebookCampaignMutation
>;
export const getFacebookAdSets = /* GraphQL */ `mutation GetFacebookAdSets($access_token: String, $campaign_id: String) {
  getFacebookAdSets(access_token: $access_token, campaign_id: $campaign_id)
}
` as GeneratedMutation<
  APITypes.GetFacebookAdSetsMutationVariables,
  APITypes.GetFacebookAdSetsMutation
>;
export const getVideoFromAuthCode = /* GraphQL */ `mutation GetVideoFromAuthCode(
  $token: String
  $advertiser_id: String
  $authCode: String
) {
  getVideoFromAuthCode(
    token: $token
    advertiser_id: $advertiser_id
    authCode: $authCode
  )
}
` as GeneratedMutation<
  APITypes.GetVideoFromAuthCodeMutationVariables,
  APITypes.GetVideoFromAuthCodeMutation
>;
export const addCreativeEarning = /* GraphQL */ `mutation AddCreativeEarning(
  $userProfileId: String!
  $creativeRequestId: String!
  $amount: Float!
  $month: String!
  $toDate: AWSDateTime!
  $fromDate: AWSDateTime!
) {
  addCreativeEarning(
    userProfileId: $userProfileId
    creativeRequestId: $creativeRequestId
    amount: $amount
    month: $month
    toDate: $toDate
    fromDate: $fromDate
  ) {
    creativeRequestEarningId
    creativeRequestId
    creatorId
    earningType
    amount
    creativeUniqueId
    currentEarnings
    month
    toDate
    fromDate
    createdAt
    updatedAt
    importedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCreativeEarningMutationVariables,
  APITypes.AddCreativeEarningMutation
>;
export const exportUsers = /* GraphQL */ `mutation ExportUsers($arg: String) {
  exportUsers(arg: $arg) {
    url
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ExportUsersMutationVariables,
  APITypes.ExportUsersMutation
>;
export const reassignCreativeRequest = /* GraphQL */ `mutation ReassignCreativeRequest($creativeRequestId: ID!, $assignee: ID!) {
  reassignCreativeRequest(
    creativeRequestId: $creativeRequestId
    assignee: $assignee
  ) {
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ReassignCreativeRequestMutationVariables,
  APITypes.ReassignCreativeRequestMutation
>;
export const exports = /* GraphQL */ `mutation Exports($entity: ENTITIES!, $brandBriefId: ID) {
  exports(entity: $entity, brandBriefId: $brandBriefId) {
    url
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ExportsMutationVariables,
  APITypes.ExportsMutation
>;
export const linkApprovedAds = /* GraphQL */ `mutation LinkApprovedAds(
  $creativeRequestId: ID!
  $tiktokData: LINK_AD_DATA
  $metaData: LINK_AD_DATA
  $googleData: LINK_AD_DATA
) {
  linkApprovedAds(
    creativeRequestId: $creativeRequestId
    tiktokData: $tiktokData
    metaData: $metaData
    googleData: $googleData
  )
}
` as GeneratedMutation<
  APITypes.LinkApprovedAdsMutationVariables,
  APITypes.LinkApprovedAdsMutation
>;
export const handleBrandBriefWhitelist = /* GraphQL */ `mutation HandleBrandBriefWhitelist(
  $brandBriefId: ID!
  $entries: [WhitelistEntriesInput!]
) {
  handleBrandBriefWhitelist(brandBriefId: $brandBriefId, entries: $entries)
}
` as GeneratedMutation<
  APITypes.HandleBrandBriefWhitelistMutationVariables,
  APITypes.HandleBrandBriefWhitelistMutation
>;
export const sendAlertMessage = /* GraphQL */ `mutation SendAlertMessage($email: String!) {
  sendAlertMessage(email: $email)
}
` as GeneratedMutation<
  APITypes.SendAlertMessageMutationVariables,
  APITypes.SendAlertMessageMutation
>;
export const sendContentStatusAlert = /* GraphQL */ `mutation SendContentStatusAlert(
  $moderatorEmail: String!
  $creativeRequestId: String!
) {
  sendContentStatusAlert(
    moderatorEmail: $moderatorEmail
    creativeRequestId: $creativeRequestId
  )
}
` as GeneratedMutation<
  APITypes.SendContentStatusAlertMutationVariables,
  APITypes.SendContentStatusAlertMutation
>;
export const importCreativeEarnings = /* GraphQL */ `mutation ImportCreativeEarnings(
  $key: String!
  $fromDate: AWSDateTime!
  $toDate: AWSDateTime!
) {
  importCreativeEarnings(key: $key, fromDate: $fromDate, toDate: $toDate)
}
` as GeneratedMutation<
  APITypes.ImportCreativeEarningsMutationVariables,
  APITypes.ImportCreativeEarningsMutation
>;
export const sendBrandBriefCreationNotification = /* GraphQL */ `mutation SendBrandBriefCreationNotification($brandBriefId: ID!) {
  sendBrandBriefCreationNotification(brandBriefId: $brandBriefId)
}
` as GeneratedMutation<
  APITypes.SendBrandBriefCreationNotificationMutationVariables,
  APITypes.SendBrandBriefCreationNotificationMutation
>;
export const deleteBestPractices = /* GraphQL */ `mutation DeleteBestPractices(
  $input: DeleteBestPracticesInput!
  $condition: ModelBestPracticesConditionInput
) {
  deleteBestPractices(input: $input, condition: $condition) {
    id
    headLine
    description
    urlPath
    active
    owner
    createdAt
    updatedAt
    userProfileBestPracticesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBestPracticesMutationVariables,
  APITypes.DeleteBestPracticesMutation
>;
export const deleteBrandProfile = /* GraphQL */ `mutation DeleteBrandProfile(
  $input: DeleteBrandProfileInput!
  $condition: ModelBrandProfileConditionInput
) {
  deleteBrandProfile(input: $input, condition: $condition) {
    id
    name
    toneVoice
    pillars
    description
    internalMission
    strapLine
    userEmail
    tiktokHandle
    vertical
    metaData
    userProfile {
      id
      uniqueId
      name
      description
      brand {
        items {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      userType
      tiktokHandler
      instagramHandler
      youtubeHandler
      bestPractices {
        items {
          id
          headLine
          description
          urlPath
          active
          owner
          createdAt
          updatedAt
          userProfileBestPracticesId
          __typename
        }
        nextToken
        __typename
      }
      tiktokAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      facebookAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      youtubeAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      userPaymentDetails {
        id
        fullName
        firstAddress
        secondAddress
        country
        accountNumber
        postCode
        swiftCode
        documentID
        owner
        userTransactions {
          nextToken
          __typename
        }
        confirmationLetter
        proofOfAddress
        branchCode
        createdAt
        updatedAt
        __typename
      }
      userWallet {
        id
        currentBalance
        currentBalanceZar
        totalEarned
        totalEarnedZar
        extraBalance
        extraBalanceZar
        createdAt
        updatedAt
        owner
        __typename
      }
      lastLoginDate
      email
      phoneNumber
      country
      hashtags
      profileContent
      avatar
      vertical
      termsAndConditions
      isNotified
      createdAt
      updatedAt
      __typename
    }
    briefs {
      items {
        id
        BriefName
        vertical
        objective
        brandBriefDetails
        brandBriefFilesUrl
        creativeInspirations
        active
        brandName
        brandImageUrl
        tiktokAdvertiserId
        creativeRequests {
          nextToken
          __typename
        }
        brandId
        brandProfile {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        adText
        country
        type
        createdAt
        updatedAt
        tikTokData {
          adIdentityId
          adgroupId
          campaignId
          adCaption
          displayName
          tikTokSparkAds
          callToAction
          landingPageUrl
          __typename
        }
        metaData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        youtubeData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        manualData {
          isManual
          adCaption
          __typename
        }
        whitelist {
          email
          isApprover
          __typename
        }
        creativeRequestsCount
        isCreationNotificationSent
        messaging
        goodPractices
        badPractices
        tags
        overview
        overviewVideoUrl
        tone
        isCompleted
        interestedUsers {
          id
          isInterested
          hasBeenShown
          __typename
        }
        commonRejectionReasons
        maybes
        owner
        __typename
      }
      nextToken
      __typename
    }
    hashtags
    personalDescription
    createdAt
    updatedAt
    userProfileBrandId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrandProfileMutationVariables,
  APITypes.DeleteBrandProfileMutation
>;
export const createCreativeRequestApproval = /* GraphQL */ `mutation CreateCreativeRequestApproval(
  $input: CreateCreativeRequestApprovalInput!
  $condition: ModelCreativeRequestApprovalConditionInput
) {
  createCreativeRequestApproval(input: $input, condition: $condition) {
    id
    creativeRequestId
    approverEmail
    approvalStatus
    reason
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCreativeRequestApprovalMutationVariables,
  APITypes.CreateCreativeRequestApprovalMutation
>;
export const updateCreativeRequestApproval = /* GraphQL */ `mutation UpdateCreativeRequestApproval(
  $input: UpdateCreativeRequestApprovalInput!
  $condition: ModelCreativeRequestApprovalConditionInput
) {
  updateCreativeRequestApproval(input: $input, condition: $condition) {
    id
    creativeRequestId
    approverEmail
    approvalStatus
    reason
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCreativeRequestApprovalMutationVariables,
  APITypes.UpdateCreativeRequestApprovalMutation
>;
export const deleteCreativeRequestApproval = /* GraphQL */ `mutation DeleteCreativeRequestApproval(
  $input: DeleteCreativeRequestApprovalInput!
  $condition: ModelCreativeRequestApprovalConditionInput
) {
  deleteCreativeRequestApproval(input: $input, condition: $condition) {
    id
    creativeRequestId
    approverEmail
    approvalStatus
    reason
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCreativeRequestApprovalMutationVariables,
  APITypes.DeleteCreativeRequestApprovalMutation
>;
export const createUserProfile = /* GraphQL */ `mutation CreateUserProfile(
  $input: CreateUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  createUserProfile(input: $input, condition: $condition) {
    id
    uniqueId
    name
    description
    brand {
      items {
        id
        name
        toneVoice
        pillars
        description
        internalMission
        strapLine
        userEmail
        tiktokHandle
        vertical
        metaData
        userProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        briefs {
          nextToken
          __typename
        }
        hashtags
        personalDescription
        createdAt
        updatedAt
        userProfileBrandId
        owner
        __typename
      }
      nextToken
      __typename
    }
    owner
    userType
    tiktokHandler
    instagramHandler
    youtubeHandler
    bestPractices {
      items {
        id
        headLine
        description
        urlPath
        active
        owner
        createdAt
        updatedAt
        userProfileBestPracticesId
        __typename
      }
      nextToken
      __typename
    }
    tiktokAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    facebookAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    youtubeAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    userPaymentDetails {
      id
      fullName
      firstAddress
      secondAddress
      country
      accountNumber
      postCode
      swiftCode
      documentID
      owner
      userTransactions {
        items {
          id
          paymentStatus
          paymentAmount
          paymentAmountZar
          userProfileId
          owner
          createdAt
          updatedAt
          userPaymentDetailsUserTransactionsId
          __typename
        }
        nextToken
        __typename
      }
      confirmationLetter
      proofOfAddress
      branchCode
      createdAt
      updatedAt
      __typename
    }
    userWallet {
      id
      currentBalance
      currentBalanceZar
      totalEarned
      totalEarnedZar
      extraBalance
      extraBalanceZar
      createdAt
      updatedAt
      owner
      __typename
    }
    lastLoginDate
    email
    phoneNumber
    country
    hashtags
    profileContent
    avatar
    vertical
    termsAndConditions
    isNotified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserProfileMutationVariables,
  APITypes.CreateUserProfileMutation
>;
export const updateUserProfile = /* GraphQL */ `mutation UpdateUserProfile(
  $input: UpdateUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  updateUserProfile(input: $input, condition: $condition) {
    id
    uniqueId
    name
    description
    brand {
      items {
        id
        name
        toneVoice
        pillars
        description
        internalMission
        strapLine
        userEmail
        tiktokHandle
        vertical
        metaData
        userProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        briefs {
          nextToken
          __typename
        }
        hashtags
        personalDescription
        createdAt
        updatedAt
        userProfileBrandId
        owner
        __typename
      }
      nextToken
      __typename
    }
    owner
    userType
    tiktokHandler
    instagramHandler
    youtubeHandler
    bestPractices {
      items {
        id
        headLine
        description
        urlPath
        active
        owner
        createdAt
        updatedAt
        userProfileBestPracticesId
        __typename
      }
      nextToken
      __typename
    }
    tiktokAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    facebookAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    youtubeAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    userPaymentDetails {
      id
      fullName
      firstAddress
      secondAddress
      country
      accountNumber
      postCode
      swiftCode
      documentID
      owner
      userTransactions {
        items {
          id
          paymentStatus
          paymentAmount
          paymentAmountZar
          userProfileId
          owner
          createdAt
          updatedAt
          userPaymentDetailsUserTransactionsId
          __typename
        }
        nextToken
        __typename
      }
      confirmationLetter
      proofOfAddress
      branchCode
      createdAt
      updatedAt
      __typename
    }
    userWallet {
      id
      currentBalance
      currentBalanceZar
      totalEarned
      totalEarnedZar
      extraBalance
      extraBalanceZar
      createdAt
      updatedAt
      owner
      __typename
    }
    lastLoginDate
    email
    phoneNumber
    country
    hashtags
    profileContent
    avatar
    vertical
    termsAndConditions
    isNotified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProfileMutationVariables,
  APITypes.UpdateUserProfileMutation
>;
export const deleteUserProfile = /* GraphQL */ `mutation DeleteUserProfile(
  $input: DeleteUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  deleteUserProfile(input: $input, condition: $condition) {
    id
    uniqueId
    name
    description
    brand {
      items {
        id
        name
        toneVoice
        pillars
        description
        internalMission
        strapLine
        userEmail
        tiktokHandle
        vertical
        metaData
        userProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        briefs {
          nextToken
          __typename
        }
        hashtags
        personalDescription
        createdAt
        updatedAt
        userProfileBrandId
        owner
        __typename
      }
      nextToken
      __typename
    }
    owner
    userType
    tiktokHandler
    instagramHandler
    youtubeHandler
    bestPractices {
      items {
        id
        headLine
        description
        urlPath
        active
        owner
        createdAt
        updatedAt
        userProfileBestPracticesId
        __typename
      }
      nextToken
      __typename
    }
    tiktokAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    facebookAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    youtubeAccountAccess {
      access_token
      advertiser_id
      advertisers_list {
        advertiser_id
        advertiser_name
        __typename
      }
      __typename
    }
    userPaymentDetails {
      id
      fullName
      firstAddress
      secondAddress
      country
      accountNumber
      postCode
      swiftCode
      documentID
      owner
      userTransactions {
        items {
          id
          paymentStatus
          paymentAmount
          paymentAmountZar
          userProfileId
          owner
          createdAt
          updatedAt
          userPaymentDetailsUserTransactionsId
          __typename
        }
        nextToken
        __typename
      }
      confirmationLetter
      proofOfAddress
      branchCode
      createdAt
      updatedAt
      __typename
    }
    userWallet {
      id
      currentBalance
      currentBalanceZar
      totalEarned
      totalEarnedZar
      extraBalance
      extraBalanceZar
      createdAt
      updatedAt
      owner
      __typename
    }
    lastLoginDate
    email
    phoneNumber
    country
    hashtags
    profileContent
    avatar
    vertical
    termsAndConditions
    isNotified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserProfileMutationVariables,
  APITypes.DeleteUserProfileMutation
>;
export const createUserPaymentDetails = /* GraphQL */ `mutation CreateUserPaymentDetails(
  $input: CreateUserPaymentDetailsInput!
  $condition: ModelUserPaymentDetailsConditionInput
) {
  createUserPaymentDetails(input: $input, condition: $condition) {
    id
    fullName
    firstAddress
    secondAddress
    country
    accountNumber
    postCode
    swiftCode
    documentID
    owner
    userTransactions {
      items {
        id
        paymentStatus
        paymentAmount
        paymentAmountZar
        userProfileId
        owner
        createdAt
        updatedAt
        userPaymentDetailsUserTransactionsId
        __typename
      }
      nextToken
      __typename
    }
    confirmationLetter
    proofOfAddress
    branchCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPaymentDetailsMutationVariables,
  APITypes.CreateUserPaymentDetailsMutation
>;
export const updateUserPaymentDetails = /* GraphQL */ `mutation UpdateUserPaymentDetails(
  $input: UpdateUserPaymentDetailsInput!
  $condition: ModelUserPaymentDetailsConditionInput
) {
  updateUserPaymentDetails(input: $input, condition: $condition) {
    id
    fullName
    firstAddress
    secondAddress
    country
    accountNumber
    postCode
    swiftCode
    documentID
    owner
    userTransactions {
      items {
        id
        paymentStatus
        paymentAmount
        paymentAmountZar
        userProfileId
        owner
        createdAt
        updatedAt
        userPaymentDetailsUserTransactionsId
        __typename
      }
      nextToken
      __typename
    }
    confirmationLetter
    proofOfAddress
    branchCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPaymentDetailsMutationVariables,
  APITypes.UpdateUserPaymentDetailsMutation
>;
export const deleteUserPaymentDetails = /* GraphQL */ `mutation DeleteUserPaymentDetails(
  $input: DeleteUserPaymentDetailsInput!
  $condition: ModelUserPaymentDetailsConditionInput
) {
  deleteUserPaymentDetails(input: $input, condition: $condition) {
    id
    fullName
    firstAddress
    secondAddress
    country
    accountNumber
    postCode
    swiftCode
    documentID
    owner
    userTransactions {
      items {
        id
        paymentStatus
        paymentAmount
        paymentAmountZar
        userProfileId
        owner
        createdAt
        updatedAt
        userPaymentDetailsUserTransactionsId
        __typename
      }
      nextToken
      __typename
    }
    confirmationLetter
    proofOfAddress
    branchCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPaymentDetailsMutationVariables,
  APITypes.DeleteUserPaymentDetailsMutation
>;
export const createUserWallet = /* GraphQL */ `mutation CreateUserWallet(
  $input: CreateUserWalletInput!
  $condition: ModelUserWalletConditionInput
) {
  createUserWallet(input: $input, condition: $condition) {
    id
    currentBalance
    currentBalanceZar
    totalEarned
    totalEarnedZar
    extraBalance
    extraBalanceZar
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserWalletMutationVariables,
  APITypes.CreateUserWalletMutation
>;
export const updateUserWallet = /* GraphQL */ `mutation UpdateUserWallet(
  $input: UpdateUserWalletInput!
  $condition: ModelUserWalletConditionInput
) {
  updateUserWallet(input: $input, condition: $condition) {
    id
    currentBalance
    currentBalanceZar
    totalEarned
    totalEarnedZar
    extraBalance
    extraBalanceZar
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserWalletMutationVariables,
  APITypes.UpdateUserWalletMutation
>;
export const deleteUserWallet = /* GraphQL */ `mutation DeleteUserWallet(
  $input: DeleteUserWalletInput!
  $condition: ModelUserWalletConditionInput
) {
  deleteUserWallet(input: $input, condition: $condition) {
    id
    currentBalance
    currentBalanceZar
    totalEarned
    totalEarnedZar
    extraBalance
    extraBalanceZar
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserWalletMutationVariables,
  APITypes.DeleteUserWalletMutation
>;
export const createUserTransactions = /* GraphQL */ `mutation CreateUserTransactions(
  $input: CreateUserTransactionsInput!
  $condition: ModelUserTransactionsConditionInput
) {
  createUserTransactions(input: $input, condition: $condition) {
    id
    paymentStatus
    paymentAmount
    paymentAmountZar
    userProfileId
    owner
    createdAt
    updatedAt
    userPaymentDetailsUserTransactionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserTransactionsMutationVariables,
  APITypes.CreateUserTransactionsMutation
>;
export const updateUserTransactions = /* GraphQL */ `mutation UpdateUserTransactions(
  $input: UpdateUserTransactionsInput!
  $condition: ModelUserTransactionsConditionInput
) {
  updateUserTransactions(input: $input, condition: $condition) {
    id
    paymentStatus
    paymentAmount
    paymentAmountZar
    userProfileId
    owner
    createdAt
    updatedAt
    userPaymentDetailsUserTransactionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserTransactionsMutationVariables,
  APITypes.UpdateUserTransactionsMutation
>;
export const deleteUserTransactions = /* GraphQL */ `mutation DeleteUserTransactions(
  $input: DeleteUserTransactionsInput!
  $condition: ModelUserTransactionsConditionInput
) {
  deleteUserTransactions(input: $input, condition: $condition) {
    id
    paymentStatus
    paymentAmount
    paymentAmountZar
    userProfileId
    owner
    createdAt
    updatedAt
    userPaymentDetailsUserTransactionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserTransactionsMutationVariables,
  APITypes.DeleteUserTransactionsMutation
>;
export const createApprovedAds = /* GraphQL */ `mutation CreateApprovedAds(
  $input: CreateApprovedAdsInput!
  $condition: ModelApprovedAdsConditionInput
) {
  createApprovedAds(input: $input, condition: $condition) {
    id
    creativeRequestId
    creativeRequest {
      id
      brandBriefId
      creatorId
      creatorProfile {
        id
        uniqueId
        name
        description
        brand {
          nextToken
          __typename
        }
        owner
        userType
        tiktokHandler
        instagramHandler
        youtubeHandler
        bestPractices {
          nextToken
          __typename
        }
        tiktokAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        facebookAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        youtubeAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        userPaymentDetails {
          id
          fullName
          firstAddress
          secondAddress
          country
          accountNumber
          postCode
          swiftCode
          documentID
          owner
          confirmationLetter
          proofOfAddress
          branchCode
          createdAt
          updatedAt
          __typename
        }
        userWallet {
          id
          currentBalance
          currentBalanceZar
          totalEarned
          totalEarnedZar
          extraBalance
          extraBalanceZar
          createdAt
          updatedAt
          owner
          __typename
        }
        lastLoginDate
        email
        phoneNumber
        country
        hashtags
        profileContent
        avatar
        vertical
        termsAndConditions
        isNotified
        createdAt
        updatedAt
        __typename
      }
      brief {
        id
        BriefName
        vertical
        objective
        brandBriefDetails
        brandBriefFilesUrl
        creativeInspirations
        active
        brandName
        brandImageUrl
        tiktokAdvertiserId
        creativeRequests {
          nextToken
          __typename
        }
        brandId
        brandProfile {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        adText
        country
        type
        createdAt
        updatedAt
        tikTokData {
          adIdentityId
          adgroupId
          campaignId
          adCaption
          displayName
          tikTokSparkAds
          callToAction
          landingPageUrl
          __typename
        }
        metaData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        youtubeData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        manualData {
          isManual
          adCaption
          __typename
        }
        whitelist {
          email
          isApprover
          __typename
        }
        creativeRequestsCount
        isCreationNotificationSent
        messaging
        goodPractices
        badPractices
        tags
        overview
        overviewVideoUrl
        tone
        isCompleted
        interestedUsers {
          id
          isInterested
          hasBeenShown
          __typename
        }
        commonRejectionReasons
        maybes
        owner
        __typename
      }
      brandProfileImageUrl
      briefDescription
      ad_id
      status
      tiktokCreativeUrl
      creativePreviewUrl
      tiktokVideoCode
      creativeTiktokHandle
      creativeYoutubeHandle
      creativeInstagramHandle
      approvedAds {
        items {
          id
          creativeRequestId
          identity_id
          item_id
          ad_id
          ad_group_id
          campaing_id
          advertiser_id
          user_profile_id
          accessToken
          ad_report
          ad_comment
          status
          adName
          approvedAdType
          owner
          createdAt
          updatedAt
          isManuallyLinked
          __typename
        }
        nextToken
        __typename
      }
      brandComment
      adminComment
      creatorComment
      adminApproval
      creatorVisibility
      BriefName
      type
      email
      uniqueId
      createdAt
      updatedAt
      brandName
      approvals {
        items {
          id
          creativeRequestId
          approverEmail
          approvalStatus
          reason
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    identity_id
    item_id
    ad_id
    ad_group_id
    campaing_id
    advertiser_id
    user_profile_id
    accessToken
    ad_report
    ad_comment
    status
    adName
    approvedAdType
    owner
    createdAt
    updatedAt
    isManuallyLinked
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateApprovedAdsMutationVariables,
  APITypes.CreateApprovedAdsMutation
>;
export const updateApprovedAds = /* GraphQL */ `mutation UpdateApprovedAds(
  $input: UpdateApprovedAdsInput!
  $condition: ModelApprovedAdsConditionInput
) {
  updateApprovedAds(input: $input, condition: $condition) {
    id
    creativeRequestId
    creativeRequest {
      id
      brandBriefId
      creatorId
      creatorProfile {
        id
        uniqueId
        name
        description
        brand {
          nextToken
          __typename
        }
        owner
        userType
        tiktokHandler
        instagramHandler
        youtubeHandler
        bestPractices {
          nextToken
          __typename
        }
        tiktokAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        facebookAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        youtubeAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        userPaymentDetails {
          id
          fullName
          firstAddress
          secondAddress
          country
          accountNumber
          postCode
          swiftCode
          documentID
          owner
          confirmationLetter
          proofOfAddress
          branchCode
          createdAt
          updatedAt
          __typename
        }
        userWallet {
          id
          currentBalance
          currentBalanceZar
          totalEarned
          totalEarnedZar
          extraBalance
          extraBalanceZar
          createdAt
          updatedAt
          owner
          __typename
        }
        lastLoginDate
        email
        phoneNumber
        country
        hashtags
        profileContent
        avatar
        vertical
        termsAndConditions
        isNotified
        createdAt
        updatedAt
        __typename
      }
      brief {
        id
        BriefName
        vertical
        objective
        brandBriefDetails
        brandBriefFilesUrl
        creativeInspirations
        active
        brandName
        brandImageUrl
        tiktokAdvertiserId
        creativeRequests {
          nextToken
          __typename
        }
        brandId
        brandProfile {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        adText
        country
        type
        createdAt
        updatedAt
        tikTokData {
          adIdentityId
          adgroupId
          campaignId
          adCaption
          displayName
          tikTokSparkAds
          callToAction
          landingPageUrl
          __typename
        }
        metaData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        youtubeData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        manualData {
          isManual
          adCaption
          __typename
        }
        whitelist {
          email
          isApprover
          __typename
        }
        creativeRequestsCount
        isCreationNotificationSent
        messaging
        goodPractices
        badPractices
        tags
        overview
        overviewVideoUrl
        tone
        isCompleted
        interestedUsers {
          id
          isInterested
          hasBeenShown
          __typename
        }
        commonRejectionReasons
        maybes
        owner
        __typename
      }
      brandProfileImageUrl
      briefDescription
      ad_id
      status
      tiktokCreativeUrl
      creativePreviewUrl
      tiktokVideoCode
      creativeTiktokHandle
      creativeYoutubeHandle
      creativeInstagramHandle
      approvedAds {
        items {
          id
          creativeRequestId
          identity_id
          item_id
          ad_id
          ad_group_id
          campaing_id
          advertiser_id
          user_profile_id
          accessToken
          ad_report
          ad_comment
          status
          adName
          approvedAdType
          owner
          createdAt
          updatedAt
          isManuallyLinked
          __typename
        }
        nextToken
        __typename
      }
      brandComment
      adminComment
      creatorComment
      adminApproval
      creatorVisibility
      BriefName
      type
      email
      uniqueId
      createdAt
      updatedAt
      brandName
      approvals {
        items {
          id
          creativeRequestId
          approverEmail
          approvalStatus
          reason
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    identity_id
    item_id
    ad_id
    ad_group_id
    campaing_id
    advertiser_id
    user_profile_id
    accessToken
    ad_report
    ad_comment
    status
    adName
    approvedAdType
    owner
    createdAt
    updatedAt
    isManuallyLinked
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateApprovedAdsMutationVariables,
  APITypes.UpdateApprovedAdsMutation
>;
export const deleteApprovedAds = /* GraphQL */ `mutation DeleteApprovedAds(
  $input: DeleteApprovedAdsInput!
  $condition: ModelApprovedAdsConditionInput
) {
  deleteApprovedAds(input: $input, condition: $condition) {
    id
    creativeRequestId
    creativeRequest {
      id
      brandBriefId
      creatorId
      creatorProfile {
        id
        uniqueId
        name
        description
        brand {
          nextToken
          __typename
        }
        owner
        userType
        tiktokHandler
        instagramHandler
        youtubeHandler
        bestPractices {
          nextToken
          __typename
        }
        tiktokAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        facebookAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        youtubeAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        userPaymentDetails {
          id
          fullName
          firstAddress
          secondAddress
          country
          accountNumber
          postCode
          swiftCode
          documentID
          owner
          confirmationLetter
          proofOfAddress
          branchCode
          createdAt
          updatedAt
          __typename
        }
        userWallet {
          id
          currentBalance
          currentBalanceZar
          totalEarned
          totalEarnedZar
          extraBalance
          extraBalanceZar
          createdAt
          updatedAt
          owner
          __typename
        }
        lastLoginDate
        email
        phoneNumber
        country
        hashtags
        profileContent
        avatar
        vertical
        termsAndConditions
        isNotified
        createdAt
        updatedAt
        __typename
      }
      brief {
        id
        BriefName
        vertical
        objective
        brandBriefDetails
        brandBriefFilesUrl
        creativeInspirations
        active
        brandName
        brandImageUrl
        tiktokAdvertiserId
        creativeRequests {
          nextToken
          __typename
        }
        brandId
        brandProfile {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        adText
        country
        type
        createdAt
        updatedAt
        tikTokData {
          adIdentityId
          adgroupId
          campaignId
          adCaption
          displayName
          tikTokSparkAds
          callToAction
          landingPageUrl
          __typename
        }
        metaData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        youtubeData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        manualData {
          isManual
          adCaption
          __typename
        }
        whitelist {
          email
          isApprover
          __typename
        }
        creativeRequestsCount
        isCreationNotificationSent
        messaging
        goodPractices
        badPractices
        tags
        overview
        overviewVideoUrl
        tone
        isCompleted
        interestedUsers {
          id
          isInterested
          hasBeenShown
          __typename
        }
        commonRejectionReasons
        maybes
        owner
        __typename
      }
      brandProfileImageUrl
      briefDescription
      ad_id
      status
      tiktokCreativeUrl
      creativePreviewUrl
      tiktokVideoCode
      creativeTiktokHandle
      creativeYoutubeHandle
      creativeInstagramHandle
      approvedAds {
        items {
          id
          creativeRequestId
          identity_id
          item_id
          ad_id
          ad_group_id
          campaing_id
          advertiser_id
          user_profile_id
          accessToken
          ad_report
          ad_comment
          status
          adName
          approvedAdType
          owner
          createdAt
          updatedAt
          isManuallyLinked
          __typename
        }
        nextToken
        __typename
      }
      brandComment
      adminComment
      creatorComment
      adminApproval
      creatorVisibility
      BriefName
      type
      email
      uniqueId
      createdAt
      updatedAt
      brandName
      approvals {
        items {
          id
          creativeRequestId
          approverEmail
          approvalStatus
          reason
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
    identity_id
    item_id
    ad_id
    ad_group_id
    campaing_id
    advertiser_id
    user_profile_id
    accessToken
    ad_report
    ad_comment
    status
    adName
    approvedAdType
    owner
    createdAt
    updatedAt
    isManuallyLinked
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteApprovedAdsMutationVariables,
  APITypes.DeleteApprovedAdsMutation
>;
export const createBestPractices = /* GraphQL */ `mutation CreateBestPractices(
  $input: CreateBestPracticesInput!
  $condition: ModelBestPracticesConditionInput
) {
  createBestPractices(input: $input, condition: $condition) {
    id
    headLine
    description
    urlPath
    active
    owner
    createdAt
    updatedAt
    userProfileBestPracticesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBestPracticesMutationVariables,
  APITypes.CreateBestPracticesMutation
>;
export const updateBestPractices = /* GraphQL */ `mutation UpdateBestPractices(
  $input: UpdateBestPracticesInput!
  $condition: ModelBestPracticesConditionInput
) {
  updateBestPractices(input: $input, condition: $condition) {
    id
    headLine
    description
    urlPath
    active
    owner
    createdAt
    updatedAt
    userProfileBestPracticesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBestPracticesMutationVariables,
  APITypes.UpdateBestPracticesMutation
>;
export const createBrandProfile = /* GraphQL */ `mutation CreateBrandProfile(
  $input: CreateBrandProfileInput!
  $condition: ModelBrandProfileConditionInput
) {
  createBrandProfile(input: $input, condition: $condition) {
    id
    name
    toneVoice
    pillars
    description
    internalMission
    strapLine
    userEmail
    tiktokHandle
    vertical
    metaData
    userProfile {
      id
      uniqueId
      name
      description
      brand {
        items {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      userType
      tiktokHandler
      instagramHandler
      youtubeHandler
      bestPractices {
        items {
          id
          headLine
          description
          urlPath
          active
          owner
          createdAt
          updatedAt
          userProfileBestPracticesId
          __typename
        }
        nextToken
        __typename
      }
      tiktokAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      facebookAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      youtubeAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      userPaymentDetails {
        id
        fullName
        firstAddress
        secondAddress
        country
        accountNumber
        postCode
        swiftCode
        documentID
        owner
        userTransactions {
          nextToken
          __typename
        }
        confirmationLetter
        proofOfAddress
        branchCode
        createdAt
        updatedAt
        __typename
      }
      userWallet {
        id
        currentBalance
        currentBalanceZar
        totalEarned
        totalEarnedZar
        extraBalance
        extraBalanceZar
        createdAt
        updatedAt
        owner
        __typename
      }
      lastLoginDate
      email
      phoneNumber
      country
      hashtags
      profileContent
      avatar
      vertical
      termsAndConditions
      isNotified
      createdAt
      updatedAt
      __typename
    }
    briefs {
      items {
        id
        BriefName
        vertical
        objective
        brandBriefDetails
        brandBriefFilesUrl
        creativeInspirations
        active
        brandName
        brandImageUrl
        tiktokAdvertiserId
        creativeRequests {
          nextToken
          __typename
        }
        brandId
        brandProfile {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        adText
        country
        type
        createdAt
        updatedAt
        tikTokData {
          adIdentityId
          adgroupId
          campaignId
          adCaption
          displayName
          tikTokSparkAds
          callToAction
          landingPageUrl
          __typename
        }
        metaData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        youtubeData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        manualData {
          isManual
          adCaption
          __typename
        }
        whitelist {
          email
          isApprover
          __typename
        }
        creativeRequestsCount
        isCreationNotificationSent
        messaging
        goodPractices
        badPractices
        tags
        overview
        overviewVideoUrl
        tone
        isCompleted
        interestedUsers {
          id
          isInterested
          hasBeenShown
          __typename
        }
        commonRejectionReasons
        maybes
        owner
        __typename
      }
      nextToken
      __typename
    }
    hashtags
    personalDescription
    createdAt
    updatedAt
    userProfileBrandId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrandProfileMutationVariables,
  APITypes.CreateBrandProfileMutation
>;
export const updateBrandProfile = /* GraphQL */ `mutation UpdateBrandProfile(
  $input: UpdateBrandProfileInput!
  $condition: ModelBrandProfileConditionInput
) {
  updateBrandProfile(input: $input, condition: $condition) {
    id
    name
    toneVoice
    pillars
    description
    internalMission
    strapLine
    userEmail
    tiktokHandle
    vertical
    metaData
    userProfile {
      id
      uniqueId
      name
      description
      brand {
        items {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      userType
      tiktokHandler
      instagramHandler
      youtubeHandler
      bestPractices {
        items {
          id
          headLine
          description
          urlPath
          active
          owner
          createdAt
          updatedAt
          userProfileBestPracticesId
          __typename
        }
        nextToken
        __typename
      }
      tiktokAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      facebookAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      youtubeAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      userPaymentDetails {
        id
        fullName
        firstAddress
        secondAddress
        country
        accountNumber
        postCode
        swiftCode
        documentID
        owner
        userTransactions {
          nextToken
          __typename
        }
        confirmationLetter
        proofOfAddress
        branchCode
        createdAt
        updatedAt
        __typename
      }
      userWallet {
        id
        currentBalance
        currentBalanceZar
        totalEarned
        totalEarnedZar
        extraBalance
        extraBalanceZar
        createdAt
        updatedAt
        owner
        __typename
      }
      lastLoginDate
      email
      phoneNumber
      country
      hashtags
      profileContent
      avatar
      vertical
      termsAndConditions
      isNotified
      createdAt
      updatedAt
      __typename
    }
    briefs {
      items {
        id
        BriefName
        vertical
        objective
        brandBriefDetails
        brandBriefFilesUrl
        creativeInspirations
        active
        brandName
        brandImageUrl
        tiktokAdvertiserId
        creativeRequests {
          nextToken
          __typename
        }
        brandId
        brandProfile {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        adText
        country
        type
        createdAt
        updatedAt
        tikTokData {
          adIdentityId
          adgroupId
          campaignId
          adCaption
          displayName
          tikTokSparkAds
          callToAction
          landingPageUrl
          __typename
        }
        metaData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        youtubeData {
          adgroupId
          campaignId
          adCaption
          callToAction
          landingPageUrl
          __typename
        }
        manualData {
          isManual
          adCaption
          __typename
        }
        whitelist {
          email
          isApprover
          __typename
        }
        creativeRequestsCount
        isCreationNotificationSent
        messaging
        goodPractices
        badPractices
        tags
        overview
        overviewVideoUrl
        tone
        isCompleted
        interestedUsers {
          id
          isInterested
          hasBeenShown
          __typename
        }
        commonRejectionReasons
        maybes
        owner
        __typename
      }
      nextToken
      __typename
    }
    hashtags
    personalDescription
    createdAt
    updatedAt
    userProfileBrandId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrandProfileMutationVariables,
  APITypes.UpdateBrandProfileMutation
>;
export const createBrandBrief = /* GraphQL */ `mutation CreateBrandBrief(
  $input: CreateBrandBriefInput!
  $condition: ModelBrandBriefConditionInput
) {
  createBrandBrief(input: $input, condition: $condition) {
    id
    BriefName
    vertical
    objective
    brandBriefDetails
    brandBriefFilesUrl
    creativeInspirations
    active
    brandName
    brandImageUrl
    tiktokAdvertiserId
    creativeRequests {
      items {
        id
        brandBriefId
        creatorId
        creatorProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        brief {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          creativeInspirations
          active
          brandName
          brandImageUrl
          tiktokAdvertiserId
          brandId
          adText
          country
          type
          createdAt
          updatedAt
          creativeRequestsCount
          isCreationNotificationSent
          messaging
          goodPractices
          badPractices
          tags
          overview
          overviewVideoUrl
          tone
          isCompleted
          commonRejectionReasons
          maybes
          owner
          __typename
        }
        brandProfileImageUrl
        briefDescription
        ad_id
        status
        tiktokCreativeUrl
        creativePreviewUrl
        tiktokVideoCode
        creativeTiktokHandle
        creativeYoutubeHandle
        creativeInstagramHandle
        approvedAds {
          nextToken
          __typename
        }
        brandComment
        adminComment
        creatorComment
        adminApproval
        creatorVisibility
        BriefName
        type
        email
        uniqueId
        createdAt
        updatedAt
        brandName
        approvals {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    brandId
    brandProfile {
      id
      name
      toneVoice
      pillars
      description
      internalMission
      strapLine
      userEmail
      tiktokHandle
      vertical
      metaData
      userProfile {
        id
        uniqueId
        name
        description
        brand {
          nextToken
          __typename
        }
        owner
        userType
        tiktokHandler
        instagramHandler
        youtubeHandler
        bestPractices {
          nextToken
          __typename
        }
        tiktokAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        facebookAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        youtubeAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        userPaymentDetails {
          id
          fullName
          firstAddress
          secondAddress
          country
          accountNumber
          postCode
          swiftCode
          documentID
          owner
          confirmationLetter
          proofOfAddress
          branchCode
          createdAt
          updatedAt
          __typename
        }
        userWallet {
          id
          currentBalance
          currentBalanceZar
          totalEarned
          totalEarnedZar
          extraBalance
          extraBalanceZar
          createdAt
          updatedAt
          owner
          __typename
        }
        lastLoginDate
        email
        phoneNumber
        country
        hashtags
        profileContent
        avatar
        vertical
        termsAndConditions
        isNotified
        createdAt
        updatedAt
        __typename
      }
      briefs {
        items {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          creativeInspirations
          active
          brandName
          brandImageUrl
          tiktokAdvertiserId
          brandId
          adText
          country
          type
          createdAt
          updatedAt
          creativeRequestsCount
          isCreationNotificationSent
          messaging
          goodPractices
          badPractices
          tags
          overview
          overviewVideoUrl
          tone
          isCompleted
          commonRejectionReasons
          maybes
          owner
          __typename
        }
        nextToken
        __typename
      }
      hashtags
      personalDescription
      createdAt
      updatedAt
      userProfileBrandId
      owner
      __typename
    }
    adText
    country
    type
    createdAt
    updatedAt
    tikTokData {
      adIdentityId
      adgroupId
      campaignId
      adCaption
      displayName
      tikTokSparkAds
      callToAction
      landingPageUrl
      __typename
    }
    metaData {
      adgroupId
      campaignId
      adCaption
      callToAction
      landingPageUrl
      __typename
    }
    youtubeData {
      adgroupId
      campaignId
      adCaption
      callToAction
      landingPageUrl
      __typename
    }
    manualData {
      isManual
      adCaption
      __typename
    }
    whitelist {
      email
      isApprover
      __typename
    }
    creativeRequestsCount
    isCreationNotificationSent
    messaging
    goodPractices
    badPractices
    tags
    overview
    overviewVideoUrl
    tone
    isCompleted
    interestedUsers {
      id
      isInterested
      hasBeenShown
      __typename
    }
    commonRejectionReasons
    maybes
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrandBriefMutationVariables,
  APITypes.CreateBrandBriefMutation
>;
export const updateBrandBrief = /* GraphQL */ `mutation UpdateBrandBrief(
  $input: UpdateBrandBriefInput!
  $condition: ModelBrandBriefConditionInput
) {
  updateBrandBrief(input: $input, condition: $condition) {
    id
    BriefName
    vertical
    objective
    brandBriefDetails
    brandBriefFilesUrl
    creativeInspirations
    active
    brandName
    brandImageUrl
    tiktokAdvertiserId
    creativeRequests {
      items {
        id
        brandBriefId
        creatorId
        creatorProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        brief {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          creativeInspirations
          active
          brandName
          brandImageUrl
          tiktokAdvertiserId
          brandId
          adText
          country
          type
          createdAt
          updatedAt
          creativeRequestsCount
          isCreationNotificationSent
          messaging
          goodPractices
          badPractices
          tags
          overview
          overviewVideoUrl
          tone
          isCompleted
          commonRejectionReasons
          maybes
          owner
          __typename
        }
        brandProfileImageUrl
        briefDescription
        ad_id
        status
        tiktokCreativeUrl
        creativePreviewUrl
        tiktokVideoCode
        creativeTiktokHandle
        creativeYoutubeHandle
        creativeInstagramHandle
        approvedAds {
          nextToken
          __typename
        }
        brandComment
        adminComment
        creatorComment
        adminApproval
        creatorVisibility
        BriefName
        type
        email
        uniqueId
        createdAt
        updatedAt
        brandName
        approvals {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    brandId
    brandProfile {
      id
      name
      toneVoice
      pillars
      description
      internalMission
      strapLine
      userEmail
      tiktokHandle
      vertical
      metaData
      userProfile {
        id
        uniqueId
        name
        description
        brand {
          nextToken
          __typename
        }
        owner
        userType
        tiktokHandler
        instagramHandler
        youtubeHandler
        bestPractices {
          nextToken
          __typename
        }
        tiktokAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        facebookAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        youtubeAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        userPaymentDetails {
          id
          fullName
          firstAddress
          secondAddress
          country
          accountNumber
          postCode
          swiftCode
          documentID
          owner
          confirmationLetter
          proofOfAddress
          branchCode
          createdAt
          updatedAt
          __typename
        }
        userWallet {
          id
          currentBalance
          currentBalanceZar
          totalEarned
          totalEarnedZar
          extraBalance
          extraBalanceZar
          createdAt
          updatedAt
          owner
          __typename
        }
        lastLoginDate
        email
        phoneNumber
        country
        hashtags
        profileContent
        avatar
        vertical
        termsAndConditions
        isNotified
        createdAt
        updatedAt
        __typename
      }
      briefs {
        items {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          creativeInspirations
          active
          brandName
          brandImageUrl
          tiktokAdvertiserId
          brandId
          adText
          country
          type
          createdAt
          updatedAt
          creativeRequestsCount
          isCreationNotificationSent
          messaging
          goodPractices
          badPractices
          tags
          overview
          overviewVideoUrl
          tone
          isCompleted
          commonRejectionReasons
          maybes
          owner
          __typename
        }
        nextToken
        __typename
      }
      hashtags
      personalDescription
      createdAt
      updatedAt
      userProfileBrandId
      owner
      __typename
    }
    adText
    country
    type
    createdAt
    updatedAt
    tikTokData {
      adIdentityId
      adgroupId
      campaignId
      adCaption
      displayName
      tikTokSparkAds
      callToAction
      landingPageUrl
      __typename
    }
    metaData {
      adgroupId
      campaignId
      adCaption
      callToAction
      landingPageUrl
      __typename
    }
    youtubeData {
      adgroupId
      campaignId
      adCaption
      callToAction
      landingPageUrl
      __typename
    }
    manualData {
      isManual
      adCaption
      __typename
    }
    whitelist {
      email
      isApprover
      __typename
    }
    creativeRequestsCount
    isCreationNotificationSent
    messaging
    goodPractices
    badPractices
    tags
    overview
    overviewVideoUrl
    tone
    isCompleted
    interestedUsers {
      id
      isInterested
      hasBeenShown
      __typename
    }
    commonRejectionReasons
    maybes
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrandBriefMutationVariables,
  APITypes.UpdateBrandBriefMutation
>;
export const deleteBrandBrief = /* GraphQL */ `mutation DeleteBrandBrief(
  $input: DeleteBrandBriefInput!
  $condition: ModelBrandBriefConditionInput
) {
  deleteBrandBrief(input: $input, condition: $condition) {
    id
    BriefName
    vertical
    objective
    brandBriefDetails
    brandBriefFilesUrl
    creativeInspirations
    active
    brandName
    brandImageUrl
    tiktokAdvertiserId
    creativeRequests {
      items {
        id
        brandBriefId
        creatorId
        creatorProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        brief {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          creativeInspirations
          active
          brandName
          brandImageUrl
          tiktokAdvertiserId
          brandId
          adText
          country
          type
          createdAt
          updatedAt
          creativeRequestsCount
          isCreationNotificationSent
          messaging
          goodPractices
          badPractices
          tags
          overview
          overviewVideoUrl
          tone
          isCompleted
          commonRejectionReasons
          maybes
          owner
          __typename
        }
        brandProfileImageUrl
        briefDescription
        ad_id
        status
        tiktokCreativeUrl
        creativePreviewUrl
        tiktokVideoCode
        creativeTiktokHandle
        creativeYoutubeHandle
        creativeInstagramHandle
        approvedAds {
          nextToken
          __typename
        }
        brandComment
        adminComment
        creatorComment
        adminApproval
        creatorVisibility
        BriefName
        type
        email
        uniqueId
        createdAt
        updatedAt
        brandName
        approvals {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
    brandId
    brandProfile {
      id
      name
      toneVoice
      pillars
      description
      internalMission
      strapLine
      userEmail
      tiktokHandle
      vertical
      metaData
      userProfile {
        id
        uniqueId
        name
        description
        brand {
          nextToken
          __typename
        }
        owner
        userType
        tiktokHandler
        instagramHandler
        youtubeHandler
        bestPractices {
          nextToken
          __typename
        }
        tiktokAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        facebookAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        youtubeAccountAccess {
          access_token
          advertiser_id
          __typename
        }
        userPaymentDetails {
          id
          fullName
          firstAddress
          secondAddress
          country
          accountNumber
          postCode
          swiftCode
          documentID
          owner
          confirmationLetter
          proofOfAddress
          branchCode
          createdAt
          updatedAt
          __typename
        }
        userWallet {
          id
          currentBalance
          currentBalanceZar
          totalEarned
          totalEarnedZar
          extraBalance
          extraBalanceZar
          createdAt
          updatedAt
          owner
          __typename
        }
        lastLoginDate
        email
        phoneNumber
        country
        hashtags
        profileContent
        avatar
        vertical
        termsAndConditions
        isNotified
        createdAt
        updatedAt
        __typename
      }
      briefs {
        items {
          id
          BriefName
          vertical
          objective
          brandBriefDetails
          brandBriefFilesUrl
          creativeInspirations
          active
          brandName
          brandImageUrl
          tiktokAdvertiserId
          brandId
          adText
          country
          type
          createdAt
          updatedAt
          creativeRequestsCount
          isCreationNotificationSent
          messaging
          goodPractices
          badPractices
          tags
          overview
          overviewVideoUrl
          tone
          isCompleted
          commonRejectionReasons
          maybes
          owner
          __typename
        }
        nextToken
        __typename
      }
      hashtags
      personalDescription
      createdAt
      updatedAt
      userProfileBrandId
      owner
      __typename
    }
    adText
    country
    type
    createdAt
    updatedAt
    tikTokData {
      adIdentityId
      adgroupId
      campaignId
      adCaption
      displayName
      tikTokSparkAds
      callToAction
      landingPageUrl
      __typename
    }
    metaData {
      adgroupId
      campaignId
      adCaption
      callToAction
      landingPageUrl
      __typename
    }
    youtubeData {
      adgroupId
      campaignId
      adCaption
      callToAction
      landingPageUrl
      __typename
    }
    manualData {
      isManual
      adCaption
      __typename
    }
    whitelist {
      email
      isApprover
      __typename
    }
    creativeRequestsCount
    isCreationNotificationSent
    messaging
    goodPractices
    badPractices
    tags
    overview
    overviewVideoUrl
    tone
    isCompleted
    interestedUsers {
      id
      isInterested
      hasBeenShown
      __typename
    }
    commonRejectionReasons
    maybes
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrandBriefMutationVariables,
  APITypes.DeleteBrandBriefMutation
>;
export const createCreativeRequest = /* GraphQL */ `mutation CreateCreativeRequest(
  $input: CreateCreativeRequestInput!
  $condition: ModelCreativeRequestConditionInput
) {
  createCreativeRequest(input: $input, condition: $condition) {
    id
    brandBriefId
    creatorId
    creatorProfile {
      id
      uniqueId
      name
      description
      brand {
        items {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      userType
      tiktokHandler
      instagramHandler
      youtubeHandler
      bestPractices {
        items {
          id
          headLine
          description
          urlPath
          active
          owner
          createdAt
          updatedAt
          userProfileBestPracticesId
          __typename
        }
        nextToken
        __typename
      }
      tiktokAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      facebookAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      youtubeAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      userPaymentDetails {
        id
        fullName
        firstAddress
        secondAddress
        country
        accountNumber
        postCode
        swiftCode
        documentID
        owner
        userTransactions {
          nextToken
          __typename
        }
        confirmationLetter
        proofOfAddress
        branchCode
        createdAt
        updatedAt
        __typename
      }
      userWallet {
        id
        currentBalance
        currentBalanceZar
        totalEarned
        totalEarnedZar
        extraBalance
        extraBalanceZar
        createdAt
        updatedAt
        owner
        __typename
      }
      lastLoginDate
      email
      phoneNumber
      country
      hashtags
      profileContent
      avatar
      vertical
      termsAndConditions
      isNotified
      createdAt
      updatedAt
      __typename
    }
    brief {
      id
      BriefName
      vertical
      objective
      brandBriefDetails
      brandBriefFilesUrl
      creativeInspirations
      active
      brandName
      brandImageUrl
      tiktokAdvertiserId
      creativeRequests {
        items {
          id
          brandBriefId
          creatorId
          brandProfileImageUrl
          briefDescription
          ad_id
          status
          tiktokCreativeUrl
          creativePreviewUrl
          tiktokVideoCode
          creativeTiktokHandle
          creativeYoutubeHandle
          creativeInstagramHandle
          brandComment
          adminComment
          creatorComment
          adminApproval
          creatorVisibility
          BriefName
          type
          email
          uniqueId
          createdAt
          updatedAt
          brandName
          owner
          __typename
        }
        nextToken
        __typename
      }
      brandId
      brandProfile {
        id
        name
        toneVoice
        pillars
        description
        internalMission
        strapLine
        userEmail
        tiktokHandle
        vertical
        metaData
        userProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        briefs {
          nextToken
          __typename
        }
        hashtags
        personalDescription
        createdAt
        updatedAt
        userProfileBrandId
        owner
        __typename
      }
      adText
      country
      type
      createdAt
      updatedAt
      tikTokData {
        adIdentityId
        adgroupId
        campaignId
        adCaption
        displayName
        tikTokSparkAds
        callToAction
        landingPageUrl
        __typename
      }
      metaData {
        adgroupId
        campaignId
        adCaption
        callToAction
        landingPageUrl
        __typename
      }
      youtubeData {
        adgroupId
        campaignId
        adCaption
        callToAction
        landingPageUrl
        __typename
      }
      manualData {
        isManual
        adCaption
        __typename
      }
      whitelist {
        email
        isApprover
        __typename
      }
      creativeRequestsCount
      isCreationNotificationSent
      messaging
      goodPractices
      badPractices
      tags
      overview
      overviewVideoUrl
      tone
      isCompleted
      interestedUsers {
        id
        isInterested
        hasBeenShown
        __typename
      }
      commonRejectionReasons
      maybes
      owner
      __typename
    }
    brandProfileImageUrl
    briefDescription
    ad_id
    status
    tiktokCreativeUrl
    creativePreviewUrl
    tiktokVideoCode
    creativeTiktokHandle
    creativeYoutubeHandle
    creativeInstagramHandle
    approvedAds {
      items {
        id
        creativeRequestId
        creativeRequest {
          id
          brandBriefId
          creatorId
          brandProfileImageUrl
          briefDescription
          ad_id
          status
          tiktokCreativeUrl
          creativePreviewUrl
          tiktokVideoCode
          creativeTiktokHandle
          creativeYoutubeHandle
          creativeInstagramHandle
          brandComment
          adminComment
          creatorComment
          adminApproval
          creatorVisibility
          BriefName
          type
          email
          uniqueId
          createdAt
          updatedAt
          brandName
          owner
          __typename
        }
        identity_id
        item_id
        ad_id
        ad_group_id
        campaing_id
        advertiser_id
        user_profile_id
        accessToken
        ad_report
        ad_comment
        status
        adName
        approvedAdType
        owner
        createdAt
        updatedAt
        isManuallyLinked
        __typename
      }
      nextToken
      __typename
    }
    brandComment
    adminComment
    creatorComment
    adminApproval
    creatorVisibility
    BriefName
    type
    email
    uniqueId
    createdAt
    updatedAt
    brandName
    approvals {
      items {
        id
        creativeRequestId
        approverEmail
        approvalStatus
        reason
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCreativeRequestMutationVariables,
  APITypes.CreateCreativeRequestMutation
>;
export const updateCreativeRequest = /* GraphQL */ `mutation UpdateCreativeRequest(
  $input: UpdateCreativeRequestInput!
  $condition: ModelCreativeRequestConditionInput
) {
  updateCreativeRequest(input: $input, condition: $condition) {
    id
    brandBriefId
    creatorId
    creatorProfile {
      id
      uniqueId
      name
      description
      brand {
        items {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      userType
      tiktokHandler
      instagramHandler
      youtubeHandler
      bestPractices {
        items {
          id
          headLine
          description
          urlPath
          active
          owner
          createdAt
          updatedAt
          userProfileBestPracticesId
          __typename
        }
        nextToken
        __typename
      }
      tiktokAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      facebookAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      youtubeAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      userPaymentDetails {
        id
        fullName
        firstAddress
        secondAddress
        country
        accountNumber
        postCode
        swiftCode
        documentID
        owner
        userTransactions {
          nextToken
          __typename
        }
        confirmationLetter
        proofOfAddress
        branchCode
        createdAt
        updatedAt
        __typename
      }
      userWallet {
        id
        currentBalance
        currentBalanceZar
        totalEarned
        totalEarnedZar
        extraBalance
        extraBalanceZar
        createdAt
        updatedAt
        owner
        __typename
      }
      lastLoginDate
      email
      phoneNumber
      country
      hashtags
      profileContent
      avatar
      vertical
      termsAndConditions
      isNotified
      createdAt
      updatedAt
      __typename
    }
    brief {
      id
      BriefName
      vertical
      objective
      brandBriefDetails
      brandBriefFilesUrl
      creativeInspirations
      active
      brandName
      brandImageUrl
      tiktokAdvertiserId
      creativeRequests {
        items {
          id
          brandBriefId
          creatorId
          brandProfileImageUrl
          briefDescription
          ad_id
          status
          tiktokCreativeUrl
          creativePreviewUrl
          tiktokVideoCode
          creativeTiktokHandle
          creativeYoutubeHandle
          creativeInstagramHandle
          brandComment
          adminComment
          creatorComment
          adminApproval
          creatorVisibility
          BriefName
          type
          email
          uniqueId
          createdAt
          updatedAt
          brandName
          owner
          __typename
        }
        nextToken
        __typename
      }
      brandId
      brandProfile {
        id
        name
        toneVoice
        pillars
        description
        internalMission
        strapLine
        userEmail
        tiktokHandle
        vertical
        metaData
        userProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        briefs {
          nextToken
          __typename
        }
        hashtags
        personalDescription
        createdAt
        updatedAt
        userProfileBrandId
        owner
        __typename
      }
      adText
      country
      type
      createdAt
      updatedAt
      tikTokData {
        adIdentityId
        adgroupId
        campaignId
        adCaption
        displayName
        tikTokSparkAds
        callToAction
        landingPageUrl
        __typename
      }
      metaData {
        adgroupId
        campaignId
        adCaption
        callToAction
        landingPageUrl
        __typename
      }
      youtubeData {
        adgroupId
        campaignId
        adCaption
        callToAction
        landingPageUrl
        __typename
      }
      manualData {
        isManual
        adCaption
        __typename
      }
      whitelist {
        email
        isApprover
        __typename
      }
      creativeRequestsCount
      isCreationNotificationSent
      messaging
      goodPractices
      badPractices
      tags
      overview
      overviewVideoUrl
      tone
      isCompleted
      interestedUsers {
        id
        isInterested
        hasBeenShown
        __typename
      }
      commonRejectionReasons
      maybes
      owner
      __typename
    }
    brandProfileImageUrl
    briefDescription
    ad_id
    status
    tiktokCreativeUrl
    creativePreviewUrl
    tiktokVideoCode
    creativeTiktokHandle
    creativeYoutubeHandle
    creativeInstagramHandle
    approvedAds {
      items {
        id
        creativeRequestId
        creativeRequest {
          id
          brandBriefId
          creatorId
          brandProfileImageUrl
          briefDescription
          ad_id
          status
          tiktokCreativeUrl
          creativePreviewUrl
          tiktokVideoCode
          creativeTiktokHandle
          creativeYoutubeHandle
          creativeInstagramHandle
          brandComment
          adminComment
          creatorComment
          adminApproval
          creatorVisibility
          BriefName
          type
          email
          uniqueId
          createdAt
          updatedAt
          brandName
          owner
          __typename
        }
        identity_id
        item_id
        ad_id
        ad_group_id
        campaing_id
        advertiser_id
        user_profile_id
        accessToken
        ad_report
        ad_comment
        status
        adName
        approvedAdType
        owner
        createdAt
        updatedAt
        isManuallyLinked
        __typename
      }
      nextToken
      __typename
    }
    brandComment
    adminComment
    creatorComment
    adminApproval
    creatorVisibility
    BriefName
    type
    email
    uniqueId
    createdAt
    updatedAt
    brandName
    approvals {
      items {
        id
        creativeRequestId
        approverEmail
        approvalStatus
        reason
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCreativeRequestMutationVariables,
  APITypes.UpdateCreativeRequestMutation
>;
export const deleteCreativeRequest = /* GraphQL */ `mutation DeleteCreativeRequest(
  $input: DeleteCreativeRequestInput!
  $condition: ModelCreativeRequestConditionInput
) {
  deleteCreativeRequest(input: $input, condition: $condition) {
    id
    brandBriefId
    creatorId
    creatorProfile {
      id
      uniqueId
      name
      description
      brand {
        items {
          id
          name
          toneVoice
          pillars
          description
          internalMission
          strapLine
          userEmail
          tiktokHandle
          vertical
          metaData
          hashtags
          personalDescription
          createdAt
          updatedAt
          userProfileBrandId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      userType
      tiktokHandler
      instagramHandler
      youtubeHandler
      bestPractices {
        items {
          id
          headLine
          description
          urlPath
          active
          owner
          createdAt
          updatedAt
          userProfileBestPracticesId
          __typename
        }
        nextToken
        __typename
      }
      tiktokAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      facebookAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      youtubeAccountAccess {
        access_token
        advertiser_id
        advertisers_list {
          advertiser_id
          advertiser_name
          __typename
        }
        __typename
      }
      userPaymentDetails {
        id
        fullName
        firstAddress
        secondAddress
        country
        accountNumber
        postCode
        swiftCode
        documentID
        owner
        userTransactions {
          nextToken
          __typename
        }
        confirmationLetter
        proofOfAddress
        branchCode
        createdAt
        updatedAt
        __typename
      }
      userWallet {
        id
        currentBalance
        currentBalanceZar
        totalEarned
        totalEarnedZar
        extraBalance
        extraBalanceZar
        createdAt
        updatedAt
        owner
        __typename
      }
      lastLoginDate
      email
      phoneNumber
      country
      hashtags
      profileContent
      avatar
      vertical
      termsAndConditions
      isNotified
      createdAt
      updatedAt
      __typename
    }
    brief {
      id
      BriefName
      vertical
      objective
      brandBriefDetails
      brandBriefFilesUrl
      creativeInspirations
      active
      brandName
      brandImageUrl
      tiktokAdvertiserId
      creativeRequests {
        items {
          id
          brandBriefId
          creatorId
          brandProfileImageUrl
          briefDescription
          ad_id
          status
          tiktokCreativeUrl
          creativePreviewUrl
          tiktokVideoCode
          creativeTiktokHandle
          creativeYoutubeHandle
          creativeInstagramHandle
          brandComment
          adminComment
          creatorComment
          adminApproval
          creatorVisibility
          BriefName
          type
          email
          uniqueId
          createdAt
          updatedAt
          brandName
          owner
          __typename
        }
        nextToken
        __typename
      }
      brandId
      brandProfile {
        id
        name
        toneVoice
        pillars
        description
        internalMission
        strapLine
        userEmail
        tiktokHandle
        vertical
        metaData
        userProfile {
          id
          uniqueId
          name
          description
          owner
          userType
          tiktokHandler
          instagramHandler
          youtubeHandler
          lastLoginDate
          email
          phoneNumber
          country
          hashtags
          profileContent
          avatar
          vertical
          termsAndConditions
          isNotified
          createdAt
          updatedAt
          __typename
        }
        briefs {
          nextToken
          __typename
        }
        hashtags
        personalDescription
        createdAt
        updatedAt
        userProfileBrandId
        owner
        __typename
      }
      adText
      country
      type
      createdAt
      updatedAt
      tikTokData {
        adIdentityId
        adgroupId
        campaignId
        adCaption
        displayName
        tikTokSparkAds
        callToAction
        landingPageUrl
        __typename
      }
      metaData {
        adgroupId
        campaignId
        adCaption
        callToAction
        landingPageUrl
        __typename
      }
      youtubeData {
        adgroupId
        campaignId
        adCaption
        callToAction
        landingPageUrl
        __typename
      }
      manualData {
        isManual
        adCaption
        __typename
      }
      whitelist {
        email
        isApprover
        __typename
      }
      creativeRequestsCount
      isCreationNotificationSent
      messaging
      goodPractices
      badPractices
      tags
      overview
      overviewVideoUrl
      tone
      isCompleted
      interestedUsers {
        id
        isInterested
        hasBeenShown
        __typename
      }
      commonRejectionReasons
      maybes
      owner
      __typename
    }
    brandProfileImageUrl
    briefDescription
    ad_id
    status
    tiktokCreativeUrl
    creativePreviewUrl
    tiktokVideoCode
    creativeTiktokHandle
    creativeYoutubeHandle
    creativeInstagramHandle
    approvedAds {
      items {
        id
        creativeRequestId
        creativeRequest {
          id
          brandBriefId
          creatorId
          brandProfileImageUrl
          briefDescription
          ad_id
          status
          tiktokCreativeUrl
          creativePreviewUrl
          tiktokVideoCode
          creativeTiktokHandle
          creativeYoutubeHandle
          creativeInstagramHandle
          brandComment
          adminComment
          creatorComment
          adminApproval
          creatorVisibility
          BriefName
          type
          email
          uniqueId
          createdAt
          updatedAt
          brandName
          owner
          __typename
        }
        identity_id
        item_id
        ad_id
        ad_group_id
        campaing_id
        advertiser_id
        user_profile_id
        accessToken
        ad_report
        ad_comment
        status
        adName
        approvedAdType
        owner
        createdAt
        updatedAt
        isManuallyLinked
        __typename
      }
      nextToken
      __typename
    }
    brandComment
    adminComment
    creatorComment
    adminApproval
    creatorVisibility
    BriefName
    type
    email
    uniqueId
    createdAt
    updatedAt
    brandName
    approvals {
      items {
        id
        creativeRequestId
        approverEmail
        approvalStatus
        reason
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCreativeRequestMutationVariables,
  APITypes.DeleteCreativeRequestMutation
>;
export const createCreativeRequestEarnings = /* GraphQL */ `mutation CreateCreativeRequestEarnings(
  $input: CreateCreativeRequestEarningsInput!
  $condition: ModelCreativeRequestEarningsConditionInput
) {
  createCreativeRequestEarnings(input: $input, condition: $condition) {
    creativeRequestEarningId
    creativeRequestId
    creatorId
    earningType
    amount
    creativeUniqueId
    currentEarnings
    month
    toDate
    fromDate
    createdAt
    updatedAt
    importedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCreativeRequestEarningsMutationVariables,
  APITypes.CreateCreativeRequestEarningsMutation
>;
export const updateCreativeRequestEarnings = /* GraphQL */ `mutation UpdateCreativeRequestEarnings(
  $input: UpdateCreativeRequestEarningsInput!
  $condition: ModelCreativeRequestEarningsConditionInput
) {
  updateCreativeRequestEarnings(input: $input, condition: $condition) {
    creativeRequestEarningId
    creativeRequestId
    creatorId
    earningType
    amount
    creativeUniqueId
    currentEarnings
    month
    toDate
    fromDate
    createdAt
    updatedAt
    importedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCreativeRequestEarningsMutationVariables,
  APITypes.UpdateCreativeRequestEarningsMutation
>;
export const deleteCreativeRequestEarnings = /* GraphQL */ `mutation DeleteCreativeRequestEarnings(
  $input: DeleteCreativeRequestEarningsInput!
  $condition: ModelCreativeRequestEarningsConditionInput
) {
  deleteCreativeRequestEarnings(input: $input, condition: $condition) {
    creativeRequestEarningId
    creativeRequestId
    creatorId
    earningType
    amount
    creativeUniqueId
    currentEarnings
    month
    toDate
    fromDate
    createdAt
    updatedAt
    importedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCreativeRequestEarningsMutationVariables,
  APITypes.DeleteCreativeRequestEarningsMutation
>;

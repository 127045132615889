import { Typography } from '@mui/material';
import { BrandBrief } from 'API';
import { Storage } from 'aws-amplify';
import Video from 'components/Video/Video';
import { FC, useCallback, useLayoutEffect, useState } from 'react';
import CreativeInspirations from '../CreativeInspirations/CreativeInspirations';

export interface ICreativeInspirationsDisplayProps {
  brandBrief: BrandBrief;
}

const CreativeInspirationsDisplay: FC<ICreativeInspirationsDisplayProps> = ({
  brandBrief,
}) => {
  const [overviewVideoUrl, setOverviewVideoUrl] = useState<string>('');
  const getOverviewVideoUrl = useCallback(async () => {
    if (!brandBrief?.overviewVideoUrl) {
      return;
    }

    const url = await Storage.get(brandBrief.overviewVideoUrl);
    setOverviewVideoUrl(url);
  }, [brandBrief?.overviewVideoUrl]);

  useLayoutEffect(() => {
    getOverviewVideoUrl();
  }, [getOverviewVideoUrl]);

  return (
    <div className="flex flex-col gap-y-4">
      <Typography className="uppercase font-oswald text-[#1D1C1C] font-[600] text-[20px]">
        Brand activation details
      </Typography>

      <div className="relative min-h-[570px] bg-[#1D1C1C] p-4 rounded-3xl flex justify-center items-center mb-[30px]">
        <div>
          <Video url={''} videoUrl={overviewVideoUrl} />
        </div>
      </div>

      {/*// FIXME: remove filter after backend data migrate*/}
      <CreativeInspirations
        urls={
          brandBrief?.creativeInspirations?.filter(
            (inspiration) => inspiration
          ) || []
        }
      />
    </div>
  );
};

export default CreativeInspirationsDisplay;

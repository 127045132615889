import {
    Box,
    FormControlLabel,
    FormHelperText,
    Switch
} from '@mui/material';
import {
    FieldValues,
    useController,
    UseControllerProps,
} from 'react-hook-form';

interface Props {
    label: string;
    disabled?: boolean;
    initialValue?: any;
}

export default <T extends FieldValues>({
    control,
    name,
    rules,
    label,
    initialValue = false,
    disabled,
}: Props & UseControllerProps<T>) => {
    const {
        field: { ref, value, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
        defaultValue: initialValue,
    });

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disabled}
                            inputRef={ref}
                            checked={value}
                            {...inputProps}
                        />
                    }
                    label={label}
                />
            </Box>
            {error?.message && (
                <Box>
                    <FormHelperText error={true}>{error.message}</FormHelperText>
                </Box>
            )}
        </>
    );
};

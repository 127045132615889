export const defultPaymentState = {
  fullName: '',
  firstAddress: '',
  secondAddress: '',
  country: '',
  accountNumber: '',
  swiftCode: '',
  documentID: '',
  postCode: '',
};

export const defultPaymentError = {
  fullName: null,
  firstAddres: null,
  secondAddres: null,
  country: null,
  accountNamber: null,
  swiftCode: null,
  documentID: null,
};

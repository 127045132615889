import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import {
  CreateCreativeRequestEarningsInput,
  CreativeRequestEarnings,
  UpdateCreativeRequestEarningsInput,
} from 'API';
import { StyledTextField } from 'components/TextField';
import { getMonth } from 'date-fns';
import { FC, useLayoutEffect, useMemo, useState } from 'react';
import { MONTH_OPTIONS } from './constants';

interface IEarningsModalProps {
  isOpen: boolean;
  title?: string;
  isLoading: boolean;
  earning?: CreativeRequestEarnings | null;
  onClose: () => void;
  onSubmit: (
    data: Omit<
      CreateCreativeRequestEarningsInput | UpdateCreativeRequestEarningsInput,
      'creatorId' | 'creativeRequestEarningId'
    >
  ) => Promise<void>;
}

const EarningsModal: FC<IEarningsModalProps> = ({
  isOpen,
  onClose,
  earning,
  onSubmit,
  isLoading,
}) => {
  const isEditing = useMemo(() => earning != null, [earning]);
  const title = useMemo(
    () => `${isEditing ? 'Edit' : 'Add'} earning`,
    [isEditing]
  );
  const [amount, setAmount] = useState<number>(0);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>();
  const [month, setMonth] = useState(MONTH_OPTIONS[0]?.value);
  const selectOptions = useMemo(
    () =>
      MONTH_OPTIONS.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      )),
    []
  );

  useLayoutEffect(() => {
    if (earning != null) {
      const monthIdx = getMonth(earning.month);
      const monthOption = MONTH_OPTIONS.find(({ value }) => value === monthIdx);

      setMonth(monthOption?.value || 0);
      setFromDate(new Date(earning.fromDate));
      setToDate(new Date(earning.toDate));
      setAmount(earning.amount);
    }
  }, [earning]);

  return (
    <Dialog fullWidth={true} open={isOpen}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DialogTitle
          sx={{
            fontFamily: 'Oswald',
            fontWeight: 600,
            fontSize: 18,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </DialogTitle>

        <DialogContent
          sx={{
            mt: 2,
            display: 'flex',
            columnGap: 2,
            ...(isLoading && {
              justifyContent: 'center',
              alignItems: 'center',
            }),
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <StyledTextField
                sx={{ width: '100%' }}
                type="number"
                name={'amount'}
                value={amount}
                onChange={(event) => setAmount(Number(event.target.value))}
                label="Media spent amount"
              />

              <DatePicker
                sx={{ width: '100%' }}
                name="fromDate"
                label="From date"
                value={fromDate}
                disabled={isEditing}
                onChange={(value) => setFromDate(value)}
              />

              <DatePicker
                name="toDate"
                label="To date"
                value={toDate}
                sx={{ width: '100%' }}
                disabled={isEditing}
                onChange={(value) => setToDate(value)}
              />

              <Select
                size="small"
                name="month"
                label="Month"
                value={month}
                disabled={isEditing}
                onChange={(event) => setMonth(Number(event?.target.value))}
              >
                {selectOptions}
              </Select>
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ pr: 3, pb: 2 }}>
          <Button
            disabled={isLoading}
            variant="outlined"
            disableRipple={true}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            disabled={isLoading}
            variant="outlined"
            disableRipple={true}
            onClick={() =>
              onSubmit({
                toDate: toDate?.toISOString() || '',
                fromDate: fromDate?.toISOString() || '',
                amount: amount || 0,
                month: String(month),
              })
            }
          >
            Save
          </Button>
        </DialogActions>
      </LocalizationProvider>
    </Dialog>
  );
};

export default EarningsModal;

import { GridColDef, GridSortItem } from '@mui/x-data-grid';

export const INITIAL_SORT = [
  { field: 'activationName', sort: 'desc' },
  { field: 'objective', sort: 'desc' },
  { field: 'country', sort: 'desc' },
  { field: 'status', sort: 'desc' },
] as GridSortItem[];

export const BRIEF_TABLE_COL_DEF: GridColDef[] = [
  {
    field: 'img',
    headerName: 'Brand',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    maxWidth: 120,
    editable: false,
    renderCell: (params) => (
      <div className="flex justify-center justify-items-center mt-3">
        <img className="rounded-full w-[40px] h-[40px]" src={params.value} />
      </div>
    ),
  },
  {
    field: 'activationName',
    headerName: 'Activation Name',
    editable: false,
    flex: 1,
  },
  {
    field: 'objective',
    headerName: 'Objective',
    editable: false,
    flex: 1,
  },
  {
    field: 'country',
    headerName: 'Country',
    editable: false,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    editable: false,
  },
  {
    align: 'center',
    headerAlign: 'center',
    field: 'details',
    headerName: 'Details',
    sortable: false,
    filterable: false,
    editable: false,
    flex: 1,
  },
  {
    align: 'center',
    headerAlign: 'center',
    field: 'edit',
    headerName: 'Edit',
    sortable: false,
    filterable: false,
    editable: false,
    flex: 1,
  },
];

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AddCreativeEarningMutation,
  AddCreativeEarningMutationVariables,
  DeleteCreativeRequestEarningsMutation,
  DeleteCreativeRequestEarningsMutationVariables,
  GetCreativeEarningsByCreativeQuery,
  GetCreativeEarningsByCreativeQueryVariables,
  UpdateCreativeRequestEarningsMutation,
  UpdateCreativeRequestEarningsMutationVariables,
} from 'API';
import {
  addCreativeEarning,
  deleteCreativeRequestEarnings,
  updateCreativeRequestEarnings,
} from 'graphql/mutations';
import { getCreativeEarningsByCreative } from 'graphql/queries';
import { getQuery } from 'hooks/utils';

export const UseAddCreativeEarning = () => {
  const [addEarning, { data, loading, error }] = useMutation<
    AddCreativeEarningMutation,
    AddCreativeEarningMutationVariables
  >(getQuery(addCreativeEarning));
  return { addEarning, data, loading, error };
};

export const UseGetCreativeEarningsByCreativeId = () => {
  const [getEarningsByCreative, { data, loading, error }] = useLazyQuery<
    GetCreativeEarningsByCreativeQuery,
    GetCreativeEarningsByCreativeQueryVariables
  >(getQuery(getCreativeEarningsByCreative));

  return { getEarningsByCreative, data, loading, error };
};

export const UseUpdateCreativeEarning = () => {
  const [updateEarning, { loading }] = useMutation<
    UpdateCreativeRequestEarningsMutation,
    UpdateCreativeRequestEarningsMutationVariables
  >(getQuery(updateCreativeRequestEarnings));

  return { updateEarning, loading };
};

export const UseDeleteCreativeEarning = () => {
  const [deleteEarning, { data, error, loading }] = useMutation<
    DeleteCreativeRequestEarningsMutation,
    DeleteCreativeRequestEarningsMutationVariables
  >(getQuery(deleteCreativeRequestEarnings));

  return { deleteEarning, data, error, loading };
};

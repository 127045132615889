import { Button } from '@mui/material';

interface IOopsPartProps {
  onCancel: () => void;
}

const OopsPart = ({ onCancel }: IOopsPartProps): JSX.Element => {
  return (
    <div className="flex flex-col gap-10 w-full bg-white shadow-xl p-6 rounded-[10px]">
      <h3 className="font-oswald font-semibold text-[18px] uppercase">OOPS</h3>
      <div className="flex gap-2 self-center">
        <p className="font-inter font-[400] text-[14px] leading-5 m-[0] p-[0]">
          You do not currently have access to this brand activation. We will be
          in touch shortly.
        </p>
      </div>

      <div className="self-end">
        <Button onClick={onCancel} disableRipple>
          CANCEL
        </Button>
      </div>
    </div>
  );
};

export default OopsPart;

import { z } from "zod";
import init from 'zod-empty';

export const schema = z.object({
    description: z.string().nullable(),
    email: z.string().nonempty(),
    id: z.string().optional(),
    instagramHandler: z.string().nullable(),
    isBrand: z.boolean().optional(),
    name: z.string().nonempty(),
    tiktokHandler: z.string().nullable(),
    userType: z.string(),
    vertical: z.string().nullable(),
    youtubeHandler: z.string().nullable(),
    uniqueId: z.string().optional(),
    country: z.string().optional(),
});


export const defaultValues = {
    ...init(schema),
    active: true,
    isBrand: false,
};
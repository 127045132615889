import { Edit, Email, EmailOutlined, OpenInNew } from '@mui/icons-material';
import { IconButton, Switch } from '@mui/material';
import { useMemo } from 'react';
import { TABLE_HEAD } from './constants';

export interface IAdminBrandBriefTableHeadProps {
  onBriefStatusChange: (brief) => void;
  onNotificationIconClick: (brief) => void;
  onDetails: (brief) => void;
  onEditBrief: (brief) => void;
}

const TableHead = ({
  onBriefStatusChange,
  onDetails,
  onNotificationIconClick,
  onEditBrief,
}: IAdminBrandBriefTableHeadProps) =>
  useMemo(
    () =>
      TABLE_HEAD.map((c) => {
        switch (c.field) {
          case 'status':
            return {
              ...c,
              renderCell: ({ row }) => {
                return (
                  <Switch
                    defaultChecked={row.active}
                    onChange={() => onBriefStatusChange(row)}
                  />
                );
              },
            };
          case 'details':
            return {
              ...c,
              renderCell: (params) => (
                <div className="h-full flex justify-center items-center">
                  <IconButton
                    className="w-[25px] h-[25px] p-[20px]"
                    onClick={() => {
                      onDetails(params.row);
                    }}
                  >
                    <OpenInNew />
                  </IconButton>
                </div>
              ),
            };
          case 'edit':
            return {
              ...c,
              renderCell: (params) => (
                <IconButton
                  onClick={() => {
                    onEditBrief(params.row);
                  }}
                >
                  <Edit />
                </IconButton>
              ),
            };
          case 'isCreationNotificationSent':
            return {
              ...c,
              renderCell: (params) => {
                const { isCreationNotificationSent } = params.row;
                return (
                  <IconButton
                    disabled={isCreationNotificationSent}
                    onClick={() => {
                      onNotificationIconClick(params.row);
                    }}
                  >
                    {isCreationNotificationSent ? <EmailOutlined /> : <Email />}
                  </IconButton>
                );
              },
            };
          default:
            return c;
        }
      }),
    []
  );

export default TableHead;

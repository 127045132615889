import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Tab, Typography } from '@mui/material';
import { BrandBrief } from 'API';
import { UseUpdateBrandBrief } from 'hooks';
import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { withAdmin } from 'state/admin';
import { AdminRoutes } from 'utils';
import GoogleTab from './components/GoogleTab/GoogleTab';
import ManualTab from './components/ManualTab/ManualTab';
import MetaTab from './components/MetaTab/MetaTab';
import SuccessModal from './components/SuccessModal/SuccessModal';
import TiktokTab from './components/TiktokTab/TiktokTab';
import { SuccessMessages, Tabs } from './constants';

export interface IThirdPageProps {
  stageBack: () => void;
  brandBrief: BrandBrief;
}

const ThirdPage: FC<IThirdPageProps> = ({ brandBrief, stageBack }) => {
  const navigate = useNavigate();
  const { updateBrief } = UseUpdateBrandBrief();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Manual);
  const onTabChange = (_, newTab: string) => {
    setSelectedTab(newTab as Tabs);
  };

  // FIXME: validation
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      tiktokData: brandBrief.tikTokData,
      manualData: brandBrief.manualData,
      youtubeData: brandBrief.youtubeData,
      metaData: brandBrief.metaData
    },
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const isFormError = !!Object.values(errors).length;
  const onSubmit = useCallback(
    async (formData) => {
      if (!brandBrief?.id) {
        return;
      }

      await updateBrief({
        variables: {
          input: {
            id: brandBrief.id,
            isCompleted: true,
            manualData: {
              isManual: Boolean(formData?.manualData.isManual),
            },
            tikTokData: {
              adCaption: formData.tiktokData?.adCaption || '',
              adgroupId: formData.tiktokData?.adgroupId || '',
              adIdentityId: formData.tiktokData?.adIdentityId || '',
              callToAction: formData.tiktokData?.callToAction || '',
              campaignId: formData.tiktokData?.campaignId || '',
              displayName: formData.tiktokData?.displayName || '',
              landingPageUrl: formData.tiktokData?.landingPageUrl || '',
              tikTokSparkAds: formData.tiktokData?.tikTokSparkAds || '',
            },
            metaData: {
              adCaption: formData.metaData?.adCaption || '',
              adgroupId: formData.metaData?.adgroupId || '',
              campaignId: formData.metaData?.campaignId || '',
              callToAction: formData.metaData?.callToAction || '',
              landingPageUrl: formData.metaData?.landingPageUrl || '',
            },
            youtubeData: {
              adCaption: formData.youtubeData?.adCaption || '',
              adgroupId: formData.youtubeData?.adgroupId || '',
              campaignId: formData.youtubeData?.campaignId || '',
              landingPageUrl: formData.youtubeData?.landingPageUrl || '',
              callToAction: formData.youtubeData?.callToAction || '',
            },
          },
        },
      });
      setIsSuccessModalOpen(true);
    },
    [brandBrief?.id, updateBrief]
  );

  const onCloseModal = () => {
    setIsSuccessModalOpen(false);
    navigate(AdminRoutes.BrandBriefs);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-4">
            <Typography className="font-oswald text-black font-[600] text-[24px] uppercase">
              Create BRAND ACTIVATION - 3 OF 3
            </Typography>

            <div className="flex flex-col gap-y-1">
              <Typography className="font-roboto text-black font-[500] text-[20px]">
                Platform Selection
              </Typography>

              <TabContext value={selectedTab}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TabList
                    TabIndicatorProps={{ className: 'bg-main-teal' }}
                    onChange={onTabChange}
                  >
                    <Tab
                      disableRipple={true}
                      className={`${selectedTab === Tabs.Manual
                        ? 'text-[#00B1B5]'
                        : 'text-gray-600'
                        } head-text font-[500]`}
                      value={Tabs.Manual}
                      label={Tabs.Manual}
                    />

                    <Tab
                      disableRipple={true}
                      className={`${selectedTab === Tabs.Tiktok
                        ? 'text-[#00B1B5]'
                        : 'text-gray-600'
                        } head-text font-[500]`}
                      value={Tabs.Tiktok}
                      label={Tabs.Tiktok}
                    />
                    <Tab
                      disableRipple={true}
                      className={`${selectedTab === Tabs.Meta
                        ? 'text-[#00B1B5]'
                        : 'text-gray-600'
                        } head-text font-[500]`}
                      value={Tabs.Meta}
                      label={Tabs.Meta}
                    />
                    <Tab
                      disableRipple={true}
                      className={`${selectedTab === Tabs.Google
                        ? 'text-[#00B1B5]'
                        : 'text-[black]'
                        } head-tex,t font-[500]`}
                      value={Tabs.Google}
                      label={Tabs.Google}
                    />
                  </TabList>
                </Box>

                <TabPanel value={Tabs.Manual}>
                  <ManualTab />
                </TabPanel>

                <TabPanel value={Tabs.Tiktok}>
                  <TiktokTab brandBrief={brandBrief} />
                </TabPanel>

                <TabPanel value={Tabs.Meta}>
                  <MetaTab brandBrief={brandBrief} />
                </TabPanel>

                <TabPanel value={Tabs.Google}>
                  <GoogleTab brandBrief={brandBrief} />
                </TabPanel>
              </TabContext>
            </div>

            <div className="flex justify-center gap-x-4">
              <Button
                disableRipple={true}
                className="bg-main-black text-white px-[22px] py-[8px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
                onClick={stageBack}
              >
                <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                  <div>Back</div>
                </div>
              </Button>

              <Button
                type="submit"
                disabled={isFormError}
                disableRipple={true}
                className="bg-main-black text-white px-[22px] py-[8px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
                onClick={() => handleSubmit(onSubmit)}
              >
                <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                  <div>Finish</div>
                </div>
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={onCloseModal}
        title={'Success'}
        message={SuccessMessages.Updated}
      />
    </>
  );
};

export default withAdmin(ThirdPage);

import { USER_TYPES } from 'API';
import { FullPageLoader, replaceSubPath } from 'components';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withProfile } from 'state/profileSteps';
import { AuthRoutes, BrandRoutes, ProfileProps } from 'utils';

const RedirectingStep: React.FC<ProfileProps> = ({
  profileState: { data },
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathnameParts = pathname.split('/');

  const redirectToValidRoute = () => {
    const isBrandViewUser = data?.userType === USER_TYPES.BRAND_VIEW;
    const isLinkingRoute =
      pathnameParts.length === 3 &&
      pathnameParts[2]?.length &&
      data?.userType === USER_TYPES.CREATIVE_USER;

    const path = isBrandViewUser
      ? BrandRoutes.BrandLoginView
      : isLinkingRoute
      ? replaceSubPath(AuthRoutes.Tiktok)
      : AuthRoutes.Dashboard;

    navigate(path);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    redirectToValidRoute();
  }, [data]);

  return <FullPageLoader />;
};

export default withProfile(RedirectingStep);

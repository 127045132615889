import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { CREATIVE_REQUEST_EARNINGS_BY_CREATOR } from 'API';
import { useMemo } from 'react';
import { TABLE_HEADER } from './constants';

export interface ICreativeUserEarningsTableProps {
  onAddEarnings: (
    earning: CREATIVE_REQUEST_EARNINGS_BY_CREATOR
  ) => Promise<void>;
}

const TableHead = ({ onAddEarnings }: ICreativeUserEarningsTableProps) =>
  useMemo(
    () =>
      TABLE_HEADER.map((c) => {
        switch (c.field) {
          case 'addEarnings':
            return {
              ...c,
              renderCell: ({ row }) => (
                <IconButton onClick={() => onAddEarnings(row)}>
                  <Add />
                </IconButton>
              ),
            };
          default:
            return c;
        }
      }),
    []
  );

export default TableHead;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloProvider } from '@apollo/client';
import { FC, Fragment, PropsWithChildren } from 'react';
import AwsConfig from './awsConfig';
import useAuthSwapListener from './useApollo';

function withApolloProvider<T>(PassedComponent: FC<T>) {
  return function Inner({ children, ...props }: PropsWithChildren<T>) {
    const { client, isApolloInitialized } = useAuthSwapListener(AwsConfig);

    if (!isApolloInitialized) return <Fragment />;
    if (!client)
      return <PassedComponent {...(props as T)}>{children}</PassedComponent>;

    return (
      <ApolloProvider client={client}>
        <PassedComponent {...(props as T)}>{children}</PassedComponent>
      </ApolloProvider>
    );
  };
}

export default withApolloProvider;

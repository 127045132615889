export const VIDEO_FILE_NAME_VALIDATION_REGEX =
  /\.mp4|\.mkv|\.avi|\.mov|\.wmv|\.flv|\.webm$/i;

export const ZIP_FILE_NAME_VALIDATION_REGEX =
  /\.zip|\.rar|\.tar\.gz|\.tar\.bz2|\.tar\.xz|\.gz|\.xz|\.iso$/i;

export const ACCEPT_ZIP_FILES = {
  'application/zip': ['.zip', '.7zip', '.gzip', '.tar'],
};

export const ACCEPT_VIDEO_FILES = {
  'video/*': ['.mp4', '.mkv', '.avi', '.mov', '.wmv', '.flv', '.webm'],
};

export const ZIP_FILES_EMPTY_MESSAGE = 'ZIP, TAR, 7z, gzip, bzip';
export const VIDEO_FILES_EMPTY_MESSAGE = 'MP4, MKV, AVI, MOV, WMV, FLV, WEBM';
export const FILE_INPUT_EMPTY_MESSAGE = 'SVG, PNG, JPG or GIF (max. 3MB)';
export const INSPIRATION_VIDEOS_COUNT_LIMIT = 6;
export const INSPIRATION_VIDEOS_SIZE_LIMIT_IN_KB = 30 * 1024;

export enum ErrorMessages {
  FileTooBig = 'Invalid file size. Max 30mb',
  BadAspectRatio = 'Invalid aspect ratio. Should be 16/9',
  LimitReached = 'Reach maximum count',
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { StyledTextField } from 'components/TextField';
import { useState } from 'react';

interface IOption {
  label: string;
}

export interface IActivationModalProps {
  title: string;
  onCancel: () => void;
  onSend: (brand: string) => void;
  options: IOption[];
  isOpen: boolean;
}
const ActivationModal = ({
  onCancel,
  onSend,
  isOpen,
  title,
  options,
}: IActivationModalProps) => {
  const [value, setValue] = useState('');
  return (
    <Dialog open={isOpen} PaperProps={{ sx: { width: '80%' } }}>
      <DialogTitle
        sx={{
          fontFamily: 'Oswald',
          fontWeight: 600,
          fontSize: 22,
          textTransform: 'uppercase',
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent
        sx={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          textOverflow: 'ellipsis',
        }}
      >
        <Autocomplete
          id="combo-box-demo"
          sx={{ py: 2, width: '100%' }}
          options={options}
          getOptionLabel={(option) => option['name']}
          onChange={(_: any, newValue) => {
            if (!newValue?.['id']) {
              return;
            }

            setValue(newValue?.['id']);
          }}
          renderInput={(params) => <StyledTextField variant='outlined' {...params} label="Brand" />}
        />
      </DialogContent>

      <DialogActions>
        <Button disableRipple={true} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!value}
          disableRipple={true}
          onClick={() => onSend(value)}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivationModal;

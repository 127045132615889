import {
  addDays,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameMonth,
} from 'date-fns';
import { isOneCalendarWeek, isRemainingPartOfMonth } from 'utils/utils';

export const isValidDateRange = (
  startDate: Date | null,
  endDate?: Date | null
) => {
  if (!startDate || !endDate) {
    return;
  }

  const isTheSameDay = isSameDay(
    startDate.toISOString(),
    endDate.toISOString()
  );

  const isOneCalendarWeekApart = isOneCalendarWeek(startDate, endDate);
  const isRemaingChunk = isRemainingPartOfMonth(startDate, endDate);
  return !isTheSameDay && (isOneCalendarWeekApart || isRemaingChunk);
};

export const getAllWeeksOfMonth = (date: Date) => {
  const monthStart = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)
  );
  const monthEnds = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      endOfMonth(monthStart).getUTCDate()
    )
  );

  let currentDate = monthStart;
  const res: string[][] = [];
  do {
    const endDate = endOfWeek(currentDate, { weekStartsOn: 1 });
    const weekEnd = Math.abs(differenceInDays(currentDate, endDate)) < 3 ? addDays(currentDate, 9) : endDate
    
    const endOfCycle = isSameMonth(weekEnd, monthStart) ? weekEnd : monthEnds;
    res.push([currentDate.toISOString(), endOfCycle.toISOString()]);
    currentDate = addDays(endOfCycle, 1);
  } while (differenceInDays(monthEnds, currentDate) > 0);

  return res;
};

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { BrandBrief } from 'API';

export interface ICreatorNotificationModalProps {
  onCancel: () => void;
  brandBrief: BrandBrief;
  isLoading?: boolean;
  onSend: (brandBrief: BrandBrief) => Promise<void>;
}

const DIALOG_TITLE = 'New activation email';
const DIALOG_CONTENT =
  'Please confirm to send New Activation email alert to all creators. ';

const CreatorNotificationModal = ({
  onCancel,
  onSend,
  brandBrief,
  isLoading,
}: ICreatorNotificationModalProps) => (
  <Dialog fullWidth={true} open={true}>
    <DialogTitle
      sx={{
        fontFamily: 'Oswald',
        textTransform: 'uppercase',
        fontWeight: 600,
      }}
    >
      {DIALOG_TITLE}
      <Typography sx={{ fontSize: 14, textTransform: 'uppercase' }}>
        {brandBrief.BriefName}
      </Typography>
    </DialogTitle>

    <DialogContent sx={{ mt: 2, fontSize: 14 }}>
      {!isLoading ? (
        DIALOG_CONTENT
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </DialogContent>
    <DialogActions>
      <Button disabled={isLoading} disableRipple={true} onClick={onCancel}>
        Cancel
      </Button>

      <Button
        disabled={isLoading}
        disableRipple={true}
        onClick={() => onSend(brandBrief)}
      >
        Send
      </Button>
    </DialogActions>
  </Dialog>
);

export default CreatorNotificationModal;

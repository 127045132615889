import {
  ADMIN_STATUS,
  CREATIVE_STATUS,
  ModelCreativeRequestFilterInput,
  ModelSortDirection,
  USER_TYPES,
} from 'API';
import {
  getlistCampaigns,
  UseCreateBrandBrief,
  UseGetBrandOptions,
  UseGetUserProfile,
} from 'hooks';
import withApolloProvider from 'hooks/apollo/withApollo';
import {
  GetAdminBrandBriefs,
  GetCreativeUsers,
  GetUsersPaymentDetails,
  UpdateUserTransactions,
} from 'hooks/query/useAdminActions';
import React, { useContext, useEffect } from 'react';
import { ProfileContext } from 'state/profileSteps';
import { v4 } from 'uuid';
import { UseAdminGetCreativeRequestsByDate } from './useCreativeRequests';

interface HocProps {
  shouldCallApi?: boolean;
}

const CREATIVE_REQUESTS_FILTER: ModelCreativeRequestFilterInput = {
  id: { attributeExists: true },
  creatorId: { attributeExists: true },
  brandBriefId: { attributeExists: true },
  adminApproval: { ne: ADMIN_STATUS.Rejected },
  status: { ne: CREATIVE_STATUS.Rejected },
};

export function withAdmin<T>(
  Component: React.FC<T & HocProps>
): React.FC<T & HocProps> {
  return withApolloProvider((props: T & HocProps) => {
    const { profileState } = useContext(ProfileContext);
    const {
      getRequests: getListCreativeRequests,
      data: creativeRequestsData,
      loading: reqloading,
    } = UseAdminGetCreativeRequestsByDate();
    const {
      getRequests: getListCreativeRequestsByLimit,
      data: creativeRequestsDataByLimit,
      loading: creativeRequestsDataByLimitLoading,
      nextToken: creativeRequestsDataByLimitNextToken,
      fetchMore: creativeRequestsByLimitFetchMore,
    } = UseAdminGetCreativeRequestsByDate();
    const {
      getUserPaymentDetails,
      paymentDetails,
      loading: paymentLoading,
    } = GetUsersPaymentDetails();
    const { creativeUsers } = GetCreativeUsers();
    const { updateTransactions } = UpdateUserTransactions();
    const { getBrandOptions, data: brandOptions } = UseGetBrandOptions();
    const { createBrief } = UseCreateBrandBrief();
    const { getProfile } = UseGetUserProfile();
    const { getCampaignList } = getlistCampaigns();

    const {
      getListBrandBriefs,
      brandBriefs,
      loading: isListBriefsLoading,
    } = GetAdminBrandBriefs();
    useEffect(() => {
      getListCreativeRequests({
        variables: {
          limit: 1000,
          type: 'CreativeRequest',
          filter: CREATIVE_REQUESTS_FILTER,
          sortDirection: ModelSortDirection.DESC,
        },
        // FIXME: error policy
        errorPolicy: 'ignore',
      });
    }, []);

    const sortCreativeRequest = async (sort: ModelSortDirection) => {
      await getListCreativeRequests({
        variables: {
          filter: CREATIVE_REQUESTS_FILTER,
          limit: 1000,
          type: 'CreativeRequest',
          sortDirection: sort,
        },
        nextFetchPolicy: 'network-only',
        // FIXME: error policy
        errorPolicy: 'ignore',
      });
    };
    const sortBrandBriefs = (sort: string) => {
      getListBrandBriefs({
        variables: {
          type: 'BrandBrief',
          sortDirection: ModelSortDirection[sort],
        },
      });
    };
    useEffect(() => {
      if (!paymentDetails.length) {
        getUserPaymentDetails();
      }
    }, [getUserPaymentDetails]);

    useEffect(() => {
      getBrandOptions();
      getListBrandBriefs({
        variables: {
          type: 'BrandBrief',
          sortDirection: ModelSortDirection.DESC,
        },
      });
    }, []);

    const createBriefWithBrandUserProfileId = async (userProfileId: string) => {
      const { data } = await getProfile({
        variables: { id: userProfileId },
      });

      if (data?.getUserProfile?.userType !== USER_TYPES.BRAND_USER) {
        console.error('Invalid user');
        return;
      }

      const brand = data.getUserProfile.brand?.items?.[0]
      if (!brand?.id) {
        console.error("Failed to create brand brief")
        return
      }

      const brandBrief = await createBrief({
        variables: {
          input: {
            id: v4(),
            BriefName: '',
            vertical: ' ',
            objective: '',
            brandBriefDetails: '',
            whitelist: [],
            brandId: brand?.id || '',
            country: data.getUserProfile.country || ' ',
            adText: '',
            active: false,
            tikTokData: {},
            metaData: {},
            youtubeData: {},
            manualData: {
              isManual: false,
            },
            isCompleted: false
          },
        },
      });

      return brandBrief.data?.createBrandBrief;
    };

    const getCampaignsAndIdentities = async ({ advertiserId, token }) => {
      const { data } = await getCampaignList({
        variables: { advertiser_id: advertiserId, token },
      });

      return data?.listCampaigns;
    };

    const paymentProps = {
      profileState: { ...profileState },
      creativeRequestsData,
      reqloading,
      brandBriefs: brandBriefs?.items,
      brandOptions,
      getBrandOptions,
      creativeUsers,
      sortCreativeRequest,
      paymentDetails,
      updateTransactions,
      sortBrandBriefs,
      paymentLoading,
      getListCreativeRequestsByLimit,
      creativeRequestsDataByLimit,
      creativeRequestsDataByLimitLoading,
      creativeRequestsDataByLimitNextToken,
      creativeRequestsByLimitFetchMore,
      isListBriefsLoading,
      createBriefWithBrandUserProfileId,
      getCampaignsAndIdentities,
    };
    return <Component {...props} {...paymentProps} />;
  });
}

import { FormControl, InputLabel, Select } from '@mui/material';
import { BrandBrief } from 'API';
import { StyledTextField } from 'components/TextField';
import { UseGetMetaAdGroups, UseGetMetaCampaigns } from 'hooks/query/useMeta';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  MetaAdGroupOptions,
  MetaCallToActionOptions,
  MetaCampaignOptions,
} from './components/MetaOptions';

export interface IMetaTabProps {
  brandBrief: BrandBrief;
}

const MetaTab: FC<IMetaTabProps> = ({ brandBrief }) => {
  const accountAccess = useMemo(
    () => brandBrief?.brandProfile?.userProfile?.facebookAccountAccess,
    [brandBrief]
  );
  const { register, watch } = useFormContext();
  const {
    getMetaCampaignList,
    data: campaigns,
    loading: campaignsLoading,
  } = UseGetMetaCampaigns();
  const {
    getMetaAdGroupList,
    data: adGroups,
    loading: adGroupsLoading,
  } = UseGetMetaAdGroups();

  const getCampaigns = useCallback(async () => {
    if (accountAccess?.access_token && accountAccess.advertiser_id) {
      await getMetaCampaignList({
        variables: {
          access_token: accountAccess.access_token,
          advertiser_id: accountAccess.advertiser_id,
        },
      });
    }
  }, [
    accountAccess?.access_token,
    accountAccess?.advertiser_id,
    getMetaCampaignList,
  ]);

  const getAdGroups = useCallback(
    async (campaignId?: string) => {
      if (
        !campaignId ||
        !accountAccess?.access_token ||
        !accountAccess.advertiser_id
      ) {
        return;
      }

      await getMetaAdGroupList({
        variables: {
          access_token: accountAccess.access_token,
          campaign_id: campaignId,
        },
      });
    },
    [
      accountAccess?.access_token,
      accountAccess?.advertiser_id,
      getMetaAdGroupList,
    ]
  );

  useLayoutEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    getAdGroups(watch('metaData.campaignId'));
  }, [getAdGroups, watch('metaData.campaignId')]);

  const campaignOptions = MetaCampaignOptions({ campaigns });
  const adGroupOptions = MetaAdGroupOptions({ adGroups });
  const metaCallToActionOptions = MetaCallToActionOptions();

  return (
    <div className="flex flex-col gap-y-4">
      <FormControl>
        <InputLabel size="small" id="meta-campaign-id">
          Select META campaign
        </InputLabel>
        <Select
          size="small"
          sx={{ width: '30%' }}
          id="meta-campaign-id"
          label="Select META campaign"
          {...register('metaData.campaignId')}
          disabled={!campaigns || !campaignsLoading}
        >
          {campaignOptions}
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel size="small" id="meta-ad-set">
          Select META ad set
        </InputLabel>
        <Select
          size="small"
          sx={{ width: '30%' }}
          id="meta-ad-set"
          label="Select META ad set"
          disabled={!adGroups || adGroupsLoading}
          {...register('metaData.adgroupId')}
        >
          {adGroupOptions}
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel size="small">Select call to action</InputLabel>
        <Select
          size="small"
          sx={{ width: '30%' }}
          label="Select call to action"
          {...register('metaData.callToAction')}
        >
          {metaCallToActionOptions}
        </Select>
      </FormControl>

      <StyledTextField
        size="small"
        sx={{ width: '30%' }}
        label="Destination URL"
        {...register('metaData.landingPageUrl')}
      />

      <StyledTextField
        size="small"
        sx={{ width: '30%' }}
        label="Ad text"
        multiline={true}
        {...register('metaData.adCaption')}
      />
    </div>
  );
};

export default MetaTab;

import Switch from 'components/Switch/Switch';
import { useFormContext } from 'react-hook-form';

const ManualTab = () => {
  const { register } = useFormContext();

  return (
    <>
      <Switch label="Enabled / Disabled" {...register('manualData.isManual')} />
    </>
  );
};

export default ManualTab;

import { GridColDef, GridSortItem } from '@mui/x-data-grid';
import { getStatusColor } from './utils';

export const TABLE_HEADER_CELLS: GridColDef[] = [
  {
    field: 'img',
    headerName: 'Creator',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    maxWidth: 120,
    renderCell: (params) => (
      <div className="flex justify-center justify-items-center mt-3">
        <img className="rounded-full w-[40px] h-[40px]" src={params.value} />
      </div>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Date of submission',
    flex: 1,
  },
  {
    field: 'briefName',
    headerName: 'Activation name',
    flex: 1,
  },
  {
    field: 'uniqueId',
    headerName: 'Creative ID',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    flex: 1,
    renderCell: (params) => (
      <div className="p-2 h-full flex flex-col justify-center align-middle">
        <div
          className={`flex justify-center ${getStatusColor(
            params.value
          )} text-sm rbg-slate-900 text-white max-h-[50px] rounded-3xl text-center align-middle`}
        >
          <p>{params.value}</p>
        </div>
      </div>
    ),
  },
  {
    field: 'details',
    headerAlign: 'center',
    headerName: 'Details',
    sortable: false,
    filterable: false,
    flex: 1,
  },
];

export const SORTING_MODEL = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
  { field: 'briefName', sort: 'desc' },
  { field: 'uniqueId', sort: 'desc' },
  { field: 'status', sort: 'desc' },
] as GridSortItem[];

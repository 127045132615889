import { Storage } from 'aws-amplify/';
import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';
import { useEffect, useState } from 'react';

interface ICreativeVideoThumbnailProps {
  videoPath: string;
}

const CreativeVideoThumbnail = ({
  videoPath,
}: ICreativeVideoThumbnailProps) => {
  const [videoUrl, setVideoUrl] = useState<string>('');

  const getVideoUrl = async () => {
    const url = await Storage.get(videoPath);
    setVideoUrl(url);
  };

  useEffect(() => {
    getVideoUrl();
  }, [videoPath]);

  return (
    <div className="max-w-[80px] max-h-[120px] relative pt-[20px]">
      <div className="overflow-hidden">
        <img
          src="/images/iPhone-bg.png"
          className="w-full h-full z-20 absolute m-auto lg:block hidden"
          alt=""
        />

        <div className="w-[71px] relative overflow-hidden rounded-[16px]">
          <VideoThumbnail
            thumbnailWrapperClassNames="z-10 max-w-[80px] max-h-[120px]"
            playButtonClassNames="rounded-[0]"
            videoUrl={videoUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default CreativeVideoThumbnail;

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Typography,
} from '@mui/material';
import { WhitelistEntry } from 'API';

interface IAccessSettingsProps {
  entry: WhitelistEntry;
  loading?: boolean;
  onAccessSettingsChange: () => void;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
}

const AccessSettings = ({
  entry,
  onSubmit,
  onCancel,
  onAccessSettingsChange,
  loading,
}: IAccessSettingsProps) => {
  return (
    <Dialog fullWidth={true} open={true}>
      <DialogTitle>
        <Box>
          <Typography
            fontSize={22}
            textTransform="uppercase"
            fontFamily="Oswald"
            fontWeight="bold"
          >
            Access Settings
          </Typography>

          <Typography fontSize={14} variant="h6">
            {entry.email}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <CircularProgress className="w-8 h-8" size="large" />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              columnGap: 1,
              pt: 2,
            }}
          >
            <Switch
              onClick={onAccessSettingsChange}
              aria-label="Content Approver"
              defaultChecked={entry.isApprover}
            />
            <Typography fontSize={14}>Content Approver</Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button disableRipple={true} onClick={onCancel}>
          Cancel
        </Button>

        <Button disableRipple={true} onClick={onSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccessSettings;

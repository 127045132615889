import { Typography } from '@mui/material';
import { Storage } from 'aws-amplify';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

export interface ICreativeInspirationsProps {
  videos: string[];
  hasOngoingUpload: boolean;
}

const CreativeInspirations: FC<ICreativeInspirationsProps> = ({
  videos,
  hasOngoingUpload,
}) => {
  const [urls, setUrls] = useState<string[]>([]);
  const getVideoUrls = useCallback(async () => {
    const urls = await Promise.all(videos.map((v) => Storage.get(v)));
    setUrls(urls);
  }, [videos, setUrls]);

  useEffect(() => {
    getVideoUrls();
  }, [videos, hasOngoingUpload, getVideoUrls]);

  const showcaseVideos = useMemo(
    () =>
      urls.map((v, i) => (
        <div
          key={i}
          className="overflow-hidden w-[180px] h-[265px] rounded-[16px]"
        >
          <video key={v} className="w-full h-auto" src={v}>
            <source src={v} type="video/mp4" />
          </video>
        </div>
      )),
    [urls]
  );

  return (
    <div className="bg-main-black flex flex-col gap-y-10 rounded-3xl p-6 h-[703px]">
      <div>
        <Typography className="uppercase font-oswald text-white font-[600] ">
          Creative inspiration
        </Typography>
      </div>

      <div className="overflow-auto max-height-[300px] max-width-[200px] flex flex-wrap justify-center 2xl:justify-start gap-4">
        {showcaseVideos}
      </div>
    </div>
  );
};

export default CreativeInspirations;

import { BrandBrief, ENTITIES } from 'API';
import { downloadFileFromUrl } from 'components/helpers';
import { UseGetNotInterestedUsersCount } from 'hooks';
import { UseExportEntitiesToCSV } from 'hooks/query/useAdminActions';
import {
    FC,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import ExportCard from '../ExportCard/ExportCard';

interface IInterestedTabProps {
    brandBrief: BrandBrief;
}

const InterestedTab: FC<IInterestedTabProps> = ({ brandBrief }) => {
    const { getNotInterestedUsers } = UseGetNotInterestedUsersCount();
    const { url, exportEntities } = UseExportEntitiesToCSV();
    const [uninterestedUsersCount, setUninterestedUsersCount] = useState(0);

    const onExport = useCallback(
        async (entity: ENTITIES) => {
            exportEntities({ variables: { entity, brandBriefId: brandBrief.id } });
        },
        [brandBrief.id, exportEntities]
    );

    useEffect(() => {
        if (url != null) {
            downloadFileFromUrl(url);
        }
    }, [url]);

    useLayoutEffect(() => {
        getUninterestedUsersCount();
    }, [brandBrief.interestedUsers]);

    const getUninterestedUsersCount = useCallback(async () => {
        const { data } = await getNotInterestedUsers({
            variables: { brandBriefId: brandBrief?.id },
        });

        setUninterestedUsersCount(data?.getNotInterestedUsersCount?.count || 0);
    }, [brandBrief?.id, getNotInterestedUsers]);

    const interestedUsersCount = useMemo(
        () =>
            brandBrief.interestedUsers?.filter((u) => u.isInterested)?.length || 0,
        [brandBrief]
    );

    return (
        <div className="flex gap-x-4">
            <ExportCard
                onExport={() => onExport(ENTITIES.InterestedUsers)}
                title="Interested"
                value={interestedUsersCount}
                isButtonDisabled={!interestedUsersCount}
            />

            <ExportCard
                isButtonDisabled={!uninterestedUsersCount}
                onExport={() => onExport(ENTITIES.UninterestedUsers)}
                title="Not interested"
                value={uninterestedUsersCount}
            />
        </div>
    );
};

export default InterestedTab;
